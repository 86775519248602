import * as clientActionTypes from '../actionTypes/clientActionTypes'

import produce from "immer";

const initialState = {
    allClientData: [],
};

const clientData = produce((state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {

        case clientActionTypes.GET_ALL_CLIENT_DATA_SUCCEED: {
            if(payload) {
                state.allClientData = payload.clients;
            }
            return state;
        }

        case clientActionTypes.GET_CLIENT_USER_DATA_SUCCEED: {
            let clientIndex = state.allClientData.findIndex((el)=> el.id === payload.id);
            state.allClientData[clientIndex].users = payload.data;
            return state;
        }

        // case clientActionTypes.ADD_CLIENT_SUCCEED: {
        //     state.allClientData.push(payload);
        //     return state;
        // }

        case clientActionTypes.UPDATE_CLIENT_SUCCEED: {
            let updateClientIndex = state.allClientData.findIndex((el)=> el.client_code === payload.client_code);
            state.allClientData[updateClientIndex] = payload;
            return state;
        }

        case clientActionTypes.ADD_CLIENT_USER_DATA_SUCCEED: {
            let updateClientIndex = state.allClientData.findIndex((el)=> el.client_code === payload.client_code);
            state.allClientData[updateClientIndex].users.push(payload.data);
            return state;
        }

        case clientActionTypes.UPDATE_CLIENT_USER_DATA_SUCCEED: {
            let updateClientIndex = state.allClientData.findIndex((el)=> el.client_code === payload.client_code);
            let updatedClient = state.allClientData[updateClientIndex];

            let updateUserIndex = updatedClient.users.findIndex((el)=> el.email === payload.data.email);
            updatedClient.users[updateUserIndex] = payload.data;

            state.allClientData[updateClientIndex] = updatedClient;
            return state;
        }

        default: {
            return state;
        }
    }
})

export default clientData;