import React, {useRef, useState} from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {PaginationProvider} from 'react-bootstrap-table2-paginator';
import filterFactory, {textFilter} from 'react-bootstrap-table2-filter';
import {useNavigate} from "react-router-dom";

import {ReactComponent as Upload} from "../../assets/Upload.svg";
import capitalizeFirstLetter from "../../helpers/capitalizeHelper";


const CatalogTable = (props) => {

    const columns = [{
        dataField: 'gtin',
        text: 'GTIN',
        style: {textAlign: 'left'},
        headerStyle: {textAlign: 'left', width: 200},
        filter: textFilter({
            className: 'table-filter-input',
            placeholder: 'GTIN',
        }),
        formatter: (rowContent, row) => {
            return(
                <div
                    onClick={()=>{
                        if(props.selectItemTable){
                            props.handleSelectItem(row.pid)
                        } else {
                            navigate(`/catalogs/${row.pid}`)
                        }
                    }}
                    style={{textDecoration:'underline', cursor: 'pointer'}}
                >
                    {row.gtin}
                </div>
            )
        }
    }, {
        dataField: 'image',
        text: 'Image',
        headerStyle: {width: 180},
        formatter: (rowContent, row) => {
            return (
                <div>
                    <img src={row.main_image} style={{maxWidth:'100%', maxHeight:'60px'}}/>
                </div>
            )
        }
    }, {
        dataField: 'brand',
        text: 'Brand',
        style: {textAlign: 'left'},
        headerStyle: {textAlign: 'left', width: 150},
        filter: textFilter({
            className: 'table-filter-input',
            placeholder: 'Brand name',
        }),
        formatter: (rowContent, row) => {
            return row.brand ? capitalizeFirstLetter(row.brand) : ''
        }
    }, {
        dataField: 'product name',
        text: 'Product Description',
        style: {textAlign: 'left', verticalAlign:'middle'},
        headerStyle: {textAlign: 'left'},
        filter: textFilter({
            className: 'table-filter-input',
            placeholder: 'Product',
        }),
        formatter: (rowContent, row) => {
            return (
                <div style={{lineHeight:'20px'}} className='d-flex align-items-center'>
                    {row["product name"]}
                </div>
            )
        }
    }, {
        dataField: 'net_weight',
        text: 'Net Content',
        style: {textAlign: 'center'},
        headerStyle: {textAlign: 'center', width: 150},
    }]


    const navigate = useNavigate();

    const tableRef = useRef(null);

    const [selected, setSelected] = useState([])
    const [sizePerPage, setSizePerPage] = useState(10)

    const handleNextPage = ({page, onPageChange}) => () => {
        onPageChange(page + 1);
        tableRef.current.scrollIntoView({behavior:'smooth', block:'start'})
    }

    const handlePrevPage = ({page, onPageChange}) => () => {
        onPageChange(page - 1);
        tableRef.current.scrollIntoView({behavior:'smooth', block:'start'})
    }

    const handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            setSelected([...selected, row.pid])
        } else {
            setSelected(selected.filter(x => x !== row.pid))
        }
    }

    const handleOnSelectAll = (isSelect, rows) => {
        if (isSelect) {
            const ids = rows.map(r => r.pid);
            setSelected([...selected, ...ids]);
        } else {
            setSelected([])
        }
    }

    const onSizePerPageChange=(size, paginationProps)=>{
        setSizePerPage(size)
        paginationProps.onPageChange(1)
    }

    const onDownloadProducts=()=>{
        props.onDownloadProducts(selected)
    }

    const options = {
        custom: true,
        totalSize: props.catalogData.length,
        sizePerPage: sizePerPage
    };

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        selected: selected,
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll,
        hideSelectColumn: props.selectItemTable
    };

    return (

        <div style={{position: 'relative', marginBottom:45}}>
            <PaginationProvider
                pagination={paginationFactory(options)}
            >
                {
                    ({
                         paginationProps,
                         paginationTableProps
                     }) => (
                        <div className='catalog-table-wrapper' ref={tableRef}>
                            <div className='pb-4 normal-font d-flex align-items-center text-start flex-column flex-md-row'>
                                <div className='d-flex align-items-center me-0 me-md-4'>
                                    <div className='me-3'>Show:</div>
                                    <div className='me-3'>
                                        <select
                                            name='size-per-page'
                                            className='form-control form-select'
                                            value={sizePerPage}
                                            onChange={(e) => onSizePerPageChange(e.target.value, paginationProps)}
                                        >
                                            <option value='10'>10</option>
                                            <option value='20'>20</option>
                                            <option value='50'>50</option>
                                            {props.catalogData && props.catalogData.length && <option value={props.catalogData.length}>All</option> }
                                        </select>
                                    </div>
                                    <div>Entries</div>
                                </div>
                                <div className='d-flex ms-0 ms-md-2'>
                                    {!props.selectItemTable && selected.length ? <div onClick={onDownloadProducts} className='small-icons-container me-2 cursor-pointer'><Upload/></div> : ''}
                                </div>
                            </div>

                            <BootstrapTable
                                keyField="pid"
                                data={props.catalogData}
                                columns={columns}
                                selectRow={selectRow}
                                classes="catalog-table-class hover"
                                headerClasses="catalog-table-header-class"
                                bodyClasses="catalog-table-body-class"
                                filter={filterFactory()}
                                {...paginationTableProps}
                            />
                            <div className='text-end pb-3' style={{
                                marginTop: "-10px",
                                position: 'absolute',
                                right: 0,
                                background: "#FFF",
                                borderRadius: "0 0 16px 16px",
                                width: "100%"
                            }}>
                                <div className='pe-4'>
                                    {paginationProps.page !== 1 && <button className="btn normal-font color-secondary"
                                                                           onClick={handlePrevPage(paginationProps)}>Prev</button>}
                                    {(Number(sizePerPage) !== paginationProps.dataSize) && (((paginationProps.dataSize + (sizePerPage - (paginationProps.dataSize % sizePerPage))) / sizePerPage) !== paginationProps.page) &&
                                        <button className="btn normal-font color-secondary"
                                                onClick={handleNextPage(paginationProps)}>Next</button>}
                                </div>
                            </div>
                        </div>
                    )
                }
            </PaginationProvider>
        </div>
    )
}

export default CatalogTable;