import * as attributeActionTypes from '../actionTypes/attributeActionTypes'

import request from '../../helpers/requestHelper';
import {getJobProductDetails, getSingleJobDetails} from "./jobActions";

export const getAllAttributeData = () => async dispatch => {
    dispatch({type: attributeActionTypes.GET_ATTRIBUTE_DATA_REQUEST})

    try {
        const res = await request({
            url: `/attribute_mapping/`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: attributeActionTypes.GET_ATTRIBUTE_DATA_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: attributeActionTypes.GET_ATTRIBUTE_DATA_FAILED,
            payload: err?.data ? err.data : err
        });
    }
}

export const uploadAttributesDataset = (file) => async dispatch => {
    dispatch({type: attributeActionTypes.UPLOAD_ATTRIBUTE_DATA_REQUEST})

    let data = new FormData();
    data.append('file', file);

    try {
        const res = await request({
            url: `/attribute_mapping/`,
            auth: true,
            method: 'POST',
            data: data
        });

        dispatch(getAllAttributeData())

        return res.message;

    } catch (err) {
        dispatch({
            type: attributeActionTypes.UPLOAD_ATTRIBUTE_DATA_FAILED,
            payload: err?.data ? err.data : err
        });
    }
}

export const updateAttributes = (data) => async dispatch => {
    dispatch({type: attributeActionTypes.ATTRIBUTE_UPDATE_REQUEST})

    try {
        const res = await request({
            url: `/attribute_verification/update_attribute`,
            auth: true,
            method: 'PUT',
            data: data
        });

        dispatch({type: attributeActionTypes.ATTRIBUTE_UPDATE_SUCCEED, payload: data.pid});
        return true;

    } catch (err) {
        dispatch({
            type: attributeActionTypes.ATTRIBUTE_UPDATE_FAILED,
            payload: err?.data ? err.data : {message: "Attributes update failed"}
        });
        return false;
    }
}

export const getAttributesFromFile = (file, mappings) => async dispatch => {
    dispatch({type: attributeActionTypes.GET_ATTRIBUTE_DATA_FROM_FILE_REQUEST})

    let data = new FormData();
    data.append('file', file);
    data.append('mapping', JSON.stringify(mappings));

    try {
        const res = await request({
            url: `/attribute_verification/`,
            auth: true,
            method: 'POST',
            data: data
        });

        let dataObj = res;
        const res2 = await dispatch(getSingleJobDetails(res.jobid, true));

        dataObj = {
            ...dataObj,
            jobDetails: res2.data
        }

        dispatch({
            type: attributeActionTypes.GET_ATTRIBUTE_DATA_FROM_FILE_SUCCEED,
            payload: dataObj
        });

        return res.jobid;

    } catch (err) {
        dispatch({
            type: attributeActionTypes.GET_ATTRIBUTE_DATA_FROM_FILE_FAILED,
            payload: err?.data ? err.data : {message: "Got an error in the file"}
        });
    }
}

export const getJobDetailsForVerification = (id, isBackground) => async dispatch => {
    if(!isBackground) dispatch({type: attributeActionTypes.GET_JOB_DETAILS_FOR_ATTRIBUTE_VERIFICATION_REQUEST})

    dispatch(getSingleJobDetails(id, true))
        .then((res2)=>{
            dispatch({
                type: attributeActionTypes.GET_JOB_DETAILS_FOR_ATTRIBUTE_VERIFICATION_SUCCEED,
                payload: res2.data
            });

            return true;
        })
        .catch(e =>{
            dispatch({
                type: attributeActionTypes.GET_JOB_DETAILS_FOR_ATTRIBUTE_VERIFICATION_FAILED,
                payload: e
            })
        })

}

export const getProductsDetailsForVerification = (id) => async dispatch => {
    dispatch({type: attributeActionTypes.GET_PRODUCT_DETAILS_FOR_ATTRIBUTE_VERIFICATION_REQUEST})

    dispatch(getJobProductDetails(id, true))
        .then((res)=>{
            let dataObj = {
                ...res,
                pid: id
            }
            dispatch({
                type: attributeActionTypes.GET_PRODUCT_DETAILS_FOR_ATTRIBUTE_VERIFICATION_SUCCEED,
                payload: dataObj
            });
        })
        .catch(e =>{
            dispatch({
                type: attributeActionTypes.GET_PRODUCT_DETAILS_FOR_ATTRIBUTE_VERIFICATION_FAILED,
                payload: e
            });
        })

}