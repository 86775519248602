import DetailItem from "../DetailItem";
import {ReactComponent as Case} from "../../assets/case.svg";
import {ReactComponent as Tick} from "../../assets/tick.svg";
import {ReactComponent as Circle} from "../../assets/circle.svg";

const JobDetails=({jobDetails})=>{

    return(
        <div className='d-block d-md-flex justify-content-between'>
            <DetailItem
                icon={<Case/>}
                number={jobDetails.number_of_jobs}
                details={"No. of Jobs"}
            />
            <DetailItem
                icon={<Tick/>}
                number={jobDetails.jobs_complete}
                details={"Job Complete"}
            />
            <DetailItem
                icon={<Circle/>}
                number={jobDetails.jobs_in_progress}
                details={"In Progress"}
            />
        </div>
    )
}

export default JobDetails;