import React from "react";
import {Spinner} from "reactstrap";


const CatalogImage=(props)=>{

    return(
        <div
            key={props.imgState[Object.keys(props.el)[0]] ? ("on-" +props.index) : ("off-" +props.index)}
            onClick={() => {
                props.setSelectedImg({
                    path: Object.keys(props.el)[0],
                    id: props.index
                })
                props.imgRef.current.scrollIntoView({behavior:'smooth', block:'center'})
                props.detailDivRef.current.scrollTop = 0;
            }}
            className={'img-placeholder d-flex align-items-center justify-content-center cursor-pointer ' + (props.catalogData.text_extracted.length === 1 ? 'mx-auto' : props.index === 0 ? 'ms-auto' : props.index === (props.catalogData.text_extracted.length - 1) ? 'me-auto' : '')}
        >
            {props.imgState[Object.keys(props.el)[0]] ?
                <img style={{maxWidth: 117, maxHeight: 117, borderRadius: '23px'}}
                     src={props.imgState[Object.keys(props.el)[0]]}/>
                :
                <div style={{minWidth: 120}} className='text-center'>
                    <Spinner style={{color: "#79BEF1"}} animation='border'>
                        {' '}
                    </Spinner>
                </div>
            }
        </div>
    )
}

export default CatalogImage;