import {saveAs} from 'file-saver';

import * as catalogActionTypes from '../actionTypes/catalogActionTypes'

import request from '../../helpers/requestHelper';


export const getAllCatalogDataDetails = () => async dispatch => {
    dispatch({type: catalogActionTypes.GET_ALL_CATALOG_DATA_REQUEST})

    try {
        const res = await request({
            url: `/products/catalog`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: catalogActionTypes.GET_ALL_CATALOG_DATA_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: catalogActionTypes.GET_ALL_CATALOG_DATA_FAILED,
            payload: err?.data ? err.data : err
        });
    }
}

export const getSingleCatalogDataDetails = (id, isForeign) => async dispatch => {
    dispatch({type: catalogActionTypes.GET_SINGLE_CATALOG_DATA_REQUEST})

    try {
        const res = await request({
            url: `/${isForeign ? "int_products" : "products"}/?pid=${id}`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: catalogActionTypes.GET_SINGLE_CATALOG_DATA_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: catalogActionTypes.GET_SINGLE_CATALOG_DATA_FAILED,
            payload: err?.data ? err.data : err
        });
    }
}

export const updateProductAttribute = (data, isForeign) => async dispatch => {
    dispatch({type: catalogActionTypes.UPDATE_CATALOG_DATA_REQUEST})

    try {
        const res = await request({
            url: `/${isForeign ? "int_products" : "products"}/update_attribute`,
            auth: true,
            method: 'PUT',
            data: data
        });

        dispatch(getSingleCatalogDataDetails(data.pid))
    } catch (err) {
        dispatch({
            type: catalogActionTypes.UPDATE_CATALOG_DATA_FAILED,
            payload: err?.data ? err.data : err
        });
    }
}


export const downloadCatalogProducts = (ids) => async dispatch => {

    let data = new FormData();
    data.append('pid_list', ids.toString());

    try {
        const res = await request({
            url: `/products/download`,
            auth: true,
            method: 'POST',
            responseType: 'arraybuffer',
            data: data
        });

        let name = `${ids.length === 1 ? ids[0] : 'products_data'}.csv`
        const blob = new Blob([res], {
            type: `application/csv`,
        });
        saveAs(blob, name)

    } catch (err) {
        dispatch({
            type: catalogActionTypes.GET_SINGLE_CATALOG_DATA_FAILED,
            payload: err?.data ? err.data : err
        });
    }
}