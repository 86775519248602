import { useRef, useState } from "react";

import Layout from "../layout";
import ImgUpload from "../components/ImgUpload";
import { useDispatch, useSelector } from "react-redux";
import { imageEnhancementRequest } from "../redux/actions/imageExtraActions";
import OverlaySpinner from "../components/OverlaySpinner";


const ImageEnhancement = () => {

    const dispatch = useDispatch();

    const imageDivRef = useRef(null)

    const gotError = useSelector((el) => el.imageExtraData.gotError);
    const isLoading = useSelector((el) => el.imageExtraData.isLoading);
    const processedImage = useSelector((el) => el.imageExtraData.image);

    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const [uploadOption, setUploadOption] = useState(1);
    const [fileState, setFileState] = useState([]);

    const onSubmitClick = () => {
        setIsSubmitClicked(true);
        dispatch(imageEnhancementRequest(fileState[0], uploadOption === 1));
        setTimeout(() => {
            imageDivRef.current?.scrollIntoView();
        }, 500)
    }

    return (
        <Layout topic={"Image Enhancement"}>
            <div className="img-enhancement-container">
                <div className='round-container p-4'>
                    <div className='sub-headers'>Image Upload</div>

                    <div className='d-flex flex-column flex-md-row align-items-md-center mt-5 mb-4 normal-font'>
                        <label htmlFor='imageOption' className='mb-2 me-4 text-nowrap'>Image Upload Option</label>
                        <select
                            name='imageOption'
                            className='form-control form-select'
                            value={uploadOption}
                            onChange={(e) => setUploadOption(Number(e.target.value))}
                            required
                        >
                            <option value="1">Background Normalization</option>
                            <option value="2">Watermark removal and Background Normalization</option>
                        </select>
                    </div>

                    <ImgUpload
                        isSingle={true}
                        fileState={fileState}
                        setFileState={setFileState}
                    />

                    <div className='mt-3 submit-btn text-end'>
                        <button
                            onClick={onSubmitClick}
                            disabled={!fileState.length}
                            className="button-custom btn"
                        >
                            Submit
                        </button>
                    </div>
                </div>

                {isSubmitClicked &&
                    <div className="row">
                        <div className='col round-container p-4 mt-4 mb-5 mx-4' ref={imageDivRef}>
                            <div className='sub-headers'><center>Original Image</center></div>

                            <div className='position-relative processed-img-container'>
                                <div className='text-center'>
                                    {(fileState && fileState[0]) ?
                                        <img
                                            alt="Processed Image"
                                            src={URL.createObjectURL(fileState[0])}
                                            style={{ maxWidth: "100%", maxHeight: "300px" }}
                                        />
                                        :
                                        <div className='text-danger pt-3 normal-font'>Please upload an Image</div>

                                    }
                                </div>
                            </div>
                        </div>
                        <div className='col round-container p-4 mt-4 mb-5 mx-4' ref={imageDivRef}>
                            <div className='sub-headers'><center>Enhanced Image</center></div>

                            <div className='position-relative processed-img-container'>
                                <OverlaySpinner isNormal={true} isLoading={isLoading} loadingText={"Processing. Please wait..."} />
                                {processedImage &&
                                    <div className='text-center'>
                                        {gotError ?
                                            <div className='text-danger pt-3 normal-font'>There was an error while processing the image</div>
                                            :
                                            <center>
                                                <img
                                                    alt="Processed Image"
                                                    src={processedImage}
                                                    style={{ maxWidth: "100%", maxHeight: "300px" }}
                                                />
                                            </center>

                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }

            </div>
        </Layout>
    )
}

export default ImageEnhancement;