export const IMAGE_ENHANCEMENT_REQUEST = "IMAGE_ENHANCEMENT_REQUEST";
export const IMAGE_ENHANCEMENT_SUCCEED = "IMAGE_ENHANCEMENT_SUCCEED";
export const IMAGE_ENHANCEMENT_FAILED = "IMAGE_ENHANCEMENT_FAILED";

export const LOGO_DETECTION_REQUEST = "LOGO_DETECTION_REQUEST";
export const LOGO_DETECTION_SUCCEED = "LOGO_DETECTION_SUCCEED";
export const LOGO_DETECTION_FAILED = "LOGO_DETECTION_FAILED";

export const GET_IMAGE_ATTRIBUTES_REQUEST = "GET_IMAGE_ATTRIBUTES_REQUEST";
export const GET_IMAGE_ATTRIBUTES_SUCCEED = "GET_IMAGE_ATTRIBUTES_SUCCEED";
export const GET_IMAGE_ATTRIBUTES_FAILED = "GET_IMAGE_ATTRIBUTES_FAILED";

export const GET_ALCOHOL_ATTRIBUTES_REQUEST = "GET_ALCOHOL_ATTRIBUTES_REQUEST";
export const GET_ALCOHOL_ATTRIBUTES_SUCCEED = "GET_ALCOHOL_ATTRIBUTES_SUCCEED";
export const GET_ALCOHOL_ATTRIBUTES_FAILED = "GET_ALCOHOL_ATTRIBUTES_FAILED";

export const GET_FASHION_ATTRIBUTES_REQUEST = "GET_FASHION_ATTRIBUTES_REQUEST";
export const GET_FASHION_ATTRIBUTES_SUCCEED = "GET_FASHION_ATTRIBUTES_SUCCEED";
export const GET_FASHION_ATTRIBUTES_FAILED = "GET_FASHION_ATTRIBUTES_FAILED";