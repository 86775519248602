export const GET_ALL_CLIENT_DATA_REQUEST = "GET_ALL_CLIENT_DATA_REQUEST";
export const GET_ALL_CLIENT_DATA_SUCCEED = "GET_ALL_CLIENT_DATA_SUCCEED";
export const GET_ALL_CLIENT_DATA_FAILED = "GET_ALL_CLIENT_DATA_FAILED";

export const ADD_CLIENT_REQUEST = "ADD_CLIENT_REQUEST";
export const ADD_CLIENT_SUCCEED = "ADD_CLIENT_SUCCEED";
export const ADD_CLIENT_FAILED = "ADD_CLIENT_FAILED";

export const UPDATE_CLIENT_REQUEST = "UPDATE_CLIENT_REQUEST";
export const UPDATE_CLIENT_SUCCEED = "UPDATE_CLIENT_SUCCEED";
export const UPDATE_CLIENT_FAILED = "UPDATE_CLIENT_FAILED";

export const GET_CLIENT_USER_DATA_REQUEST = "GET_CLIENT_USER_DATA_REQUEST";
export const GET_CLIENT_USER_DATA_SUCCEED = "GET_CLIENT_USER_DATA_SUCCEED";
export const GET_CLIENT_USER_DATA_FAILED = "GET_CLIENT_USER_DATA_FAILED";

export const ADD_CLIENT_USER_DATA_REQUEST = "ADD_CLIENT_USER_DATA_REQUEST";
export const ADD_CLIENT_USER_DATA_SUCCEED = "ADD_CLIENT_USER_DATA_SUCCEED";
export const ADD_CLIENT_USER_DATA_FAILED = "ADD_CLIENT_USER_DATA_FAILED";

export const UPDATE_CLIENT_USER_DATA_REQUEST = "UPDATE_CLIENT_USER_DATA_REQUEST";
export const UPDATE_CLIENT_USER_DATA_SUCCEED = "UPDATE_CLIENT_USER_DATA_SUCCEED";
export const UPDATE_CLIENT_USER_DATA_FAILED = "UPDATE_CLIENT_USER_DATA_FAILED";

export const DEACTIVATE_USER_REQUEST = "DEACTIVATE_USER_REQUEST";
export const DEACTIVATE_USER_SUCCEED = "DEACTIVATE_USER_SUCCEED";
export const DEACTIVATE_USER_FAILED = "DEACTIVATE_USER_FAILED";

export const ACTIVATE_USER_REQUEST = "ACTIVATE_USER_REQUEST";
export const ACTIVATE_USER_SUCCEED = "ACTIVATE_USER_SUCCEED";
export const ACTIVATE_USER_FAILED = "ACTIVATE_USER_FAILED";
