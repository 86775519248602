import React, {useEffect, useState} from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {PaginationProvider} from 'react-bootstrap-table2-paginator';
import {useNavigate} from "react-router-dom";

import {ReactComponent as AddUser} from '../../assets/AddUser.svg'
import {ReactComponent as Edit} from '../../assets/Edit.svg'
import {ReactComponent as Plus} from '../../assets/Plus.svg'
import {ReactComponent as Minus} from '../../assets/Minus.svg'

import capitalizeFirstLetter from "../../helpers/capitalizeHelper";
import {Spinner} from "reactstrap";

const SIZE_PER_PAGE = 10;

const ClientTable = (props) => {

    const columns = [{
        dataField: 'client',
        text: 'Client',
        style: {textAlign: 'left'},
        headerStyle: {textAlign: 'left'},
    }, {
        dataField: 'Usage',
        text: 'Usage',
        formatter: (rowContent, row) => {
            return (
                <div>{(row.usage && row.usage[0]) ? row.usage[0].processed : row.usage}/{row.add_limit}</div>
            )
        }
    }, {
        dataField: 'user_limit',
        text: 'Users',
    }, {
        dataField: '',
        text: '',
        formatter: (rowContent, row) => {
            return (
                <div>
                    <div style={{width: 'max-content', margin: 'auto'}}
                         onClick={() => props.modalHandler('user', false, row)}
                         className='cursor-pointer d-flex align-items-center justify-content-center'>
                        <AddUser/>
                        <div className='ms-2 color-secondary' style={{fontWeight: 600}}>Add User</div>
                    </div>
                </div>
            )
        }
    }, {
        dataField: '',
        text: '',
        formatter: (rowContent, row) => {
            return (
                <div>
                    <div style={{width: 'max-content', margin: 'auto'}}
                         onClick={() => props.modalHandler('client', true, row)}
                         className='cursor-pointer d-flex align-items-center justify-content-end'>
                        <Edit/>
                        <div className='ms-2 color-secondary' style={{fontWeight: 600}}>Edit</div>
                    </div>
                </div>
            )
        }
    }];

    const navigate = useNavigate();

    const [expanded, setExpanded] = useState([]);

    useEffect(() => {
        if (props.client_code) {
            setExpanded([...expanded, props.client_code])
        }
    }, [])

    const handleNextPage = ({page, onPageChange}) => () => {
        onPageChange(page + 1);
    }

    const handlePrevPage = ({page, onPageChange}) => () => {
        onPageChange(page - 1);
    }

    const handleOnExpand = (row, isExpand) => {
        if (isExpand) {
            // props.getClientUserData(row.client_code)
            setExpanded([...expanded, row.client_code]);
        } else {
            setExpanded(expanded.filter(x => x !== row.client_code));
        }
    }

    const options = {
        custom: true,
        totalSize: props.clientData.length,
        sizePerPage: SIZE_PER_PAGE
    };

    const expandRow = {
        expanded: expanded,
        onExpand: handleOnExpand,
        showExpandColumn: true,
        expandByColumnOnly: true,
        renderer: row => {
            if (props.isLoading) {
                return (
                    <Spinner style={{color: "#79BEF1"}} animation='border'>
                        {' '}
                    </Spinner>
                )
            } else {
                let dataset = props.clientData.find((item) => item.client_code === row.client_code)?.users

                return (
                    <div className='ps-5 pe-5 pt-1 pb-1'>
                        {dataset.map((user) => {
                            return (
                                <div className='d-flex expanded-client-area'>
                                    <div style={props.client_code ? {
                                        maxWidth: '30%',
                                        minWidth: '30%'
                                    } : {}}>{user.full_name}</div>
                                    <div style={props.client_code ? {
                                        maxWidth: '34%',
                                        minWidth: '34%'
                                    } : {}}>{user.email}</div>
                                    <div style={props.client_code ? {
                                        maxWidth: '25%',
                                        minWidth: '25%'
                                    } : {}}>{user.roles}</div>

                                    <div onClick={() => {
                                        let data = {
                                            ...user,
                                            client_code: row.client_code
                                        };

                                        props.modalHandler('user', true, data)
                                    }}
                                         className='d-flex align-items-center justify-content-end cursor-pointer'>
                                        <Edit/>
                                        <div className='ms-2 color-secondary' style={{fontWeight: 600}}>Edit</div>
                                    </div>

                                </div>
                            )
                        })}
                    </div>
                )
            }
        },
        expandHeaderColumnRenderer: () => {
            return <div></div>
        },
        expandColumnRenderer: ({expanded}) => {
            if (expanded) {
                return (<Minus className='color-secondary expand-icon' style={{cursor: 'pointer'}}/>)
            } else {
                return (<Plus className='color-secondary expand-icon' style={{cursor: 'pointer'}}/>)
            }
        }
    };

    return (

        <div style={{marginBottom: 50, position: 'relative'}}>
            <PaginationProvider
                pagination={paginationFactory(options)}
            >
                {
                    ({
                         paginationProps,
                         paginationTableProps
                     }) => (
                        <div className='clients-table-wrapper'>
                            {!props.client_code &&
                                <div className='text-end pt-4 clients-table-button-wrapper'>
                                    <button onClick={() => props.modalHandler('client', false)}
                                            className="button-custom btn p-4 pt-2 pb-2" style={{width: "max-content"}}>
                                        <Plus
                                            className='me-2' color={'white'}/> Add New Client
                                    </button>
                                </div>
                            }

                            <BootstrapTable
                                keyField="client_code"
                                data={props.clientData}
                                columns={props.client_code ? columns.splice(0, columns.length - 1) : columns}
                                expandRow={expandRow}
                                classes="job-table-class"
                                headerClasses="job-table-header-class"
                                bodyClasses="job-table-body-class"
                                {...paginationTableProps}
                            />
                            <div className='text-end pb-3' style={{
                                marginTop: "-17px",
                                position: 'absolute',
                                right: 0,
                                background: "#FFF",
                                borderRadius: "0 0 16px 16px",
                                width: "100%"
                            }}>
                                <div className='pe-4'>
                                    {paginationProps.page !== 1 && <button className="btn normal-font color-secondary"
                                                                           onClick={handlePrevPage(paginationProps)}>Prev</button>}
                                    {((paginationProps.totalSize + (SIZE_PER_PAGE - (paginationProps.totalSize % SIZE_PER_PAGE))) / SIZE_PER_PAGE) !== paginationProps.page &&
                                        <button className="btn normal-font color-secondary"
                                                onClick={handleNextPage(paginationProps)}>Next</button>}
                                </div>
                            </div>
                        </div>
                    )
                }
            </PaginationProvider>
        </div>
    )
}

export default ClientTable;