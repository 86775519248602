import { useEffect, useRef, useState } from "react";
import { FileDrop } from "react-file-drop";
import { Form } from "react-bootstrap";

import { ReactComponent as Files } from '../../assets/files.svg'
import { ReactComponent as Gallery } from '../../assets/Gallery.svg'
import { ReactComponent as Delete } from "../../assets/Delete.svg";

const FileUpload = (props) => {

    const fileInputRef = useRef(null);

    useEffect(() => {
        if (!props.fileState && fileInputRef?.current) {
            // fileInputRef.current.files = new FileList();
        }
    }, [props.fileState])

    const onFileInputChange = (event) => {
        onFileDrop(event.target.files)
    }

    const onTargetClick = (e) => {
        fileInputRef.current.click()
    }

    const onFileDrop = (files) => {
        let arr = [];

        if (props.fileType === 'image') {
            for (let i = 0; i < props.fileState.length; i++) {
                arr.push(props.fileState[i])
            }
        }

        for (let i = 0; i < files.length; i++) {
            let exe = files[i].name.split('.').pop().toLowerCase();
            if (props.fileType === 'csv') {
                if (exe === 'csv' || exe === 'xls' || exe === 'xlsx') {
                    arr.push(files[i]);
                    break;
                }
            } else {
                if (exe === 'png' || exe === 'jpg' || exe === 'jpeg') {
                    arr.push(files[i]);
                }
            }
        }
        props.setFileState(arr);
    }

    const onChangeHandler = (e) => {
        const { name, value } = e.target;

        props.setFormState({
            ...props.formState,
            [name]: value
        })
    }

    const onDeleteClick = (index) => {
        let arr = [];
        for (let i = 0; i < props.fileState.length; i++) {
            if (i !== index) arr.push(props.fileState[i])
        }
        props.setFileState(arr);
    }

    return (
        <div className='mt-0'>
            <div>
                <input
                    type='file'
                    name='file'
                    id='file'
                    accept={props.fileType === 'csv' ? '.csv, .xls, .xlsx' : '.png, .jpg, .jpeg'}
                    ref={fileInputRef}
                    onChange={onFileInputChange}
                    style={{ display: 'none' }}
                    multiple={props.fileType !== 'csv'}
                />
                {props.fileType === 'csv' ?
                    <div style={{ width: '200px' }}>
                        {props.fileState.length ?
                            <FileDrop
                                onTargetClick={onTargetClick}
                                onDrop={onFileDrop}
                            >
                                <div style={{ width: '100%' }}>
                                    <div className='file-name'>{props.fileState[0].name}</div>
                                </div>
                            </FileDrop>
                            :
                            <FileDrop
                                onTargetClick={onTargetClick}
                                onDrop={onFileDrop}
                            >
                                <div>
                                    <div className='text-center'><Files height={50} /></div>
                                </div>
                            </FileDrop>
                        }
                    </div>
                    : props.fileType === 'url' ?
                        <div className='row form-list'>
                            <div className='mt-4 col-md-6'>
                                <label htmlFor='gtin' className='mb-2'>GTIN Number *</label>
                                <input
                                    type='text'
                                    name='gtin'
                                    className='form-control'
                                    placeholder='Enter GTIN...'
                                    value={props.formState.gtin}
                                    onChange={onChangeHandler}
                                />
                            </div>

                            <div className='mt-4 col-md-6'>
                                <label htmlFor='url' className='mb-2'>URL</label>
                                <input
                                    type='text'
                                    name='url'
                                    className='form-control'
                                    placeholder='Enter URL...'
                                    value={props.formState.url}
                                    onChange={onChangeHandler}
                                />
                            </div>
                        </div>
                        :
                        <div>
                            <div className='mt-4 col-md-12 form-list'>
                                <label htmlFor='gtin' className='mb-2'>GTIN Number *</label>
                                <input
                                    type='text'
                                    name='gtin'
                                    className='form-control'
                                    placeholder='Enter GTIN...'
                                    value={props.formState.gtin}
                                    onChange={onChangeHandler}
                                    style={{ maxWidth: '100%' }}
                                />
                            </div>

                            <div className='mt-4 col-md-12 form-list'>
                                <label htmlFor='image' className='mb-2'>Upload Image *</label>
                                <FileDrop
                                    onTargetClick={onTargetClick}
                                    onDrop={onFileDrop}
                                    style={{ height: 1000 }}
                                >
                                    <div className='p-5' style={{ maxWidth: '100%', width: '100%' }}>
                                        <Gallery height={50} />
                                        <div className='mt-4'>Click or drag images here to upload</div>
                                        <div style={{ color: '#B3B3C5' }} className='mt-2'>
                                            (Front image, Back image, Side images (optional), Nutrition image (optional)
                                        </div>

                                        <div className='mt-5 pt-2 pb-3 d-flex mx-auto img-container-upload'>
                                            {props.fileState.map((el, index) => {
                                                return (
                                                    <div onClick={(e) => { e.stopPropagation() }} style={{ position: 'relative', cursor: 'default' }} className={'img-placeholder d-flex align-items-center justify-content-center no-click ' + (props.fileState.length === 1 ? 'mx-auto' : index === 0 ? 'ms-auto' : index === (props.fileState.length - 1) ? 'me-auto' : '')}>
                                                        <img onClick={(e) => { e.stopPropagation() }} style={{ maxWidth: 117, maxHeight: 117, cursor: 'default' }} src={URL.createObjectURL(el)} className='no-click' />
                                                        <div onClick={(e) => {
                                                            e.stopPropagation();
                                                            onDeleteClick(index)
                                                        }} className='delete-icon cursor-pointer'><Delete /></div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </FileDrop>
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}

export default FileUpload;