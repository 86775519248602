import * as actionTypes from '../actionTypes/jobActionTypes'

import produce from "immer";

const initialState = {
    allJobData: {
        data: [],
        jobs_complete: 0,
        jobs_in_progress: 0,
        number_of_jobs: 0,
    },
    singleJobData:null,
    jobProductData: null,
    jobFileHeaders: [],
    mappingHeaders: []
};

const jobData = produce(
    (state = initialState, action) => {
        const { type, payload } = action;

        switch (type) {

            case actionTypes.GET_ALL_JOB_DATA_SUCCEED: {
                if(payload) {
                    state.allJobData.data = payload.data ? payload.data.reverse() : [];
                    state.allJobData.jobs_complete = parseInt(payload.jobs_complete) ? payload.jobs_complete : 0;
                    state.allJobData.jobs_in_progress = parseInt(payload.jobs_in_progress) ? payload.jobs_in_progress : 0;
                    state.allJobData.number_of_jobs = parseInt(payload.number_of_jobs) ? payload.number_of_jobs : 0;
                }
                return state;
            }
            case actionTypes.GET_SINGLE_JOB_DATA_SUCCEED: {
                state.singleJobData = payload.data;
                return state;
            }
            case actionTypes.GET_JOB_PRODUCT_DATA_SUCCEED: {
                state.jobProductData = payload.data;
                return state;
            }
            case actionTypes.GET_JOB_FILE_HEADERS_REQUEST: {
                state.jobFileHeaders = [];
                return state;
            }
            case actionTypes.GET_HEADERS_DATA_SUCCEED: {
                state.mappingHeaders = payload.Our_headers;
                return state;
            }
            case actionTypes.GET_JOB_FILE_HEADERS_SUCCEED: {
                state.jobFileHeaders = payload.headers;
                return state;
            }
            case actionTypes.SUBMIT_JOB_FILE_SUCCEED: {
                state.jobFileHeaders = [];
                return state;
            }

            default: {
                return state;
            }
        }
    }
);

export default jobData;
