export const GET_ALL_CATALOG_DATA_REQUEST = "GET_ALL_CATALOG_DATA_REQUEST";
export const GET_ALL_CATALOG_DATA_SUCCEED = "GET_ALL_CATALOG_DATA_SUCCEED";
export const GET_ALL_CATALOG_DATA_FAILED = "GET_ALL_CATALOG_DATA_FAILED";

export const GET_SINGLE_CATALOG_DATA_REQUEST = "GET_SINGLE_CATALOG_DATA_REQUEST";
export const GET_SINGLE_CATALOG_DATA_SUCCEED = "GET_SINGLE_CATALOG_DATA_SUCCEED";
export const GET_SINGLE_CATALOG_DATA_FAILED = "GET_SINGLE_CATALOG_DATA_FAILED";

export const UPDATE_CATALOG_DATA_REQUEST = "UPDATE_CATALOG_DATA_REQUEST";
export const UPDATE_CATALOG_DATA_SUCCEED = "UPDATE_CATALOG_DATA_SUCCEED";
export const UPDATE_CATALOG_DATA_FAILED = "UPDATE_CATALOG_DATA_FAILED";