import {applyMiddleware, createStore, combineReducers} from 'redux';
import  thunk from 'redux-thunk';

import jobData from "./reducers/jobReducer";
import loadingData from "./reducers/loadingReducer";
import clientData from "./reducers/clientReducer";
import attributeData from "./reducers/attributeReducer";
import brandData from "./reducers/brandReducer";
import catalogData from "./reducers/catalogReducer";
import imageExtraData from "./reducers/imageExtraReducer";

const store = createStore(
    combineReducers({
        jobData,
        loadingData,
        clientData,
        attributeData,
        brandData,
        catalogData,
        imageExtraData
    }),
    applyMiddleware(thunk)
);

export default store;