import * as brandActionTypes from '../actionTypes/brandActionTypes'

import produce from "immer";

const initialState = {
    brandsList: [],
    brand_count: '-'
};

const brandData = produce(
    (state = initialState, action) => {
        const { type, payload } = action;

        switch (type) {
            case brandActionTypes.GET_BRAND_DATA_SUCCEED: {
                state.brandsList = payload.brand.splice(0, 50);
                state.brand_count = payload.total_brand;
                return state;
            }

            default: {
                return state;
            }
        }
    }
);

export default brandData;
