import React from 'react';
import { Spinner } from 'reactstrap';


const OverlaySpinner=(props)=>{

	const styles = {
		full:{
			backgroundColor:'rgba(255,255,255,0.98)', 
			width:'100%', 
			height:'100%', 
			position:'fixed',
			zIndex:'1011',
		},
		fullSpinner:{
			position:'fixed', 
			top:'45%', 
			left:'50%',
			marginLeft:'-17px',
			zIndex:'1011'
		},
		normal:{
			backgroundColor:'rgba(255,255,255,0.98)',
			width:'100%',
			height:'100%',
			position:'absolute',
			zIndex:'1000',
			marginLeft:'-17px',
			marginTop:'-45px',
		},
		normalSpinner:{
			position:'absolute',
			top:'45%',
			left:'48%',
			zIndex:'1000'
		}
	}

	if(props.isLoading){
		return(
			<div style={props.isNormal ? styles.normal : styles.full}>
				<div style={props.isNormal ? styles.normalSpinner : styles.fullSpinner}>
					<Spinner style={{color:"#79BEF1"}}  animation='border'>
					{' '}
					</Spinner>
					{props.loadingText &&
						<div className='normal-font loading-text color-secondary'>{props.loadingText}</div>
					}
				</div>
	        </div>	
		)
	}else{
		return null
	}
}

export default OverlaySpinner;