import React, { useState } from "react";
import { ProSidebar, SidebarHeader, SidebarFooter, SidebarContent, MenuItem, Menu, SubMenu } from 'react-pro-sidebar';
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import Header from "../components/Header";

import { ReactComponent as Company } from '../assets/sidebar/Company.svg'
import { ReactComponent as Catalog } from '../assets/sidebar/Catalog.svg'
import { ReactComponent as Dashboard } from '../assets/sidebar/Dashboard.svg'
import { ReactComponent as Jobs } from '../assets/sidebar/Jobs.svg'
import { ReactComponent as Lock } from '../assets/sidebar/Lock.svg'
import { ReactComponent as Database } from '../assets/sidebar/Database.svg'
import { ReactComponent as Mapping } from '../assets/sidebar/Mapping.svg'
import { ReactComponent as User } from '../assets/sidebar/User.svg'
import { ReactComponent as Logout } from '../assets/sidebar/Logout.svg'

import { JWTReader } from "../helpers/JWTReader";
import { userLogout } from "../redux/actions/authActions";

const Layout = ({ children, topic }) => {

    const location = useLocation();
    const dispatch = useDispatch();

    const userDetails = JWTReader(localStorage.getItem('token'));

    const [toggled, setToggled] = useState(false)

    const onLogoutClick = () => {
        dispatch(userLogout());
    }

    return (
        <div className='d-flex min-vw-100'>
            <div>
                <ProSidebar
                    toggled={toggled}
                    onToggle={() => setToggled(!toggled)}
                    breakPoint={'md'}
                    style={{ minHeight: '100vh', maxHeight: '100vh' }}
                >
                    <SidebarHeader>
                        <div className='color-secondary ps-4 pe-4 pb-4 text-center'
                            style={{ fontSize: 20, paddingTop: '2.2rem' }}>CommerceFlow
                        </div>
                    </SidebarHeader>
                    <SidebarContent>
                        <Menu>
                            <MenuItem
                                icon={<Dashboard />}
                                className={location.pathname === '/' && 'selected-menu-item'}
                            >
                                Dashboard
                                <Link to={'/'} />
                            </MenuItem>

                            <MenuItem
                                icon={<Catalog />}
                                className={location.pathname.includes('/catalogs') && 'selected-menu-item'}
                            >
                                Catalog
                                <Link to={'/catalogs'} />
                            </MenuItem>

                            <SubMenu
                                title="Jobs"
                                icon={<Jobs />}
                            >
                                <SubMenu
                                    title="Attribute Extraction"
                                    icon={<Jobs />}
                                >
                                    <SubMenu
                                        title="CPG"
                                        icon={<Jobs />}
                                    >
                                        <MenuItem
                                            icon={<Jobs />}
                                            className={location.pathname.includes('/jobs') && 'selected-menu-item'}
                                        >
                                            English
                                            <Link to={'/jobs'} />
                                        </MenuItem>
                                        <MenuItem
                                            icon={<Jobs />}
                                            className={location.pathname.includes('/int_jobs') && 'selected-menu-item'}
                                        >
                                            Foreign Languages
                                            <Link to={'/int_jobs'} />
                                        </MenuItem>

                                    </SubMenu>

                                    <MenuItem
                                        icon={<Jobs />}
                                        className={location.pathname.includes('/alcohol') && 'selected-menu-item'}
                                    >
                                        Alcohol
                                        <Link to={'/alcohol'} />
                                    </MenuItem>

                                </SubMenu>

                                <MenuItem
                                    icon={<Jobs />}
                                    className={location.pathname.includes('/gtin-finder') && 'selected-menu-item'}
                                >
                                    Gtin Finder
                                    <Link to={'/gtin-finder'} />
                                </MenuItem>

                                <MenuItem
                                    className={location.pathname.includes('/attribute-verification') && 'selected-menu-item'}
                                    icon={<Mapping />}
                                >
                                    Attribute Verification
                                    <Link to={'/attribute-verification'} />
                                </MenuItem>

                                <MenuItem
                                    icon={<Jobs />}
                                    className={location.pathname.includes('/image-enhancement') && 'selected-menu-item'}
                                >
                                    Image Enhancement
                                    <Link to={'/image-enhancement'} />
                                </MenuItem>

                                <MenuItem
                                    icon={<Jobs />}
                                    className={location.pathname.includes('/logo-detector') && 'selected-menu-item'}
                                >
                                    Logo Detector
                                    <Link to={'/logo-detector'} />
                                </MenuItem>
                                <MenuItem
                                    icon={<Jobs />}
                                    className={location.pathname.includes('/furniture') && 'selected-menu-item'}
                                >
                                    Furniture
                                    <Link to={'/furniture'} />
                                </MenuItem>
                                <MenuItem
                                    icon={<Jobs />}
                                    className={location.pathname.includes('/fashion') && 'selected-menu-item'}
                                >
                                    Fashion
                                    <Link to={'/fashion'} />
                                </MenuItem>
                            </SubMenu>

                            <div className='mb-4 mt-4' style={{
                                cursor: 'default',
                                color: "#4F4E699E",
                                fontSize: 13,
                                marginLeft: "27px"
                            }}>Control Panel
                            </div>

                            {userDetails.roles !== 'USER' &&
                                <MenuItem
                                    icon={<Company />}
                                    className={location.pathname.includes('/companies') && 'selected-menu-item'}
                                >
                                    Company
                                    <Link to={'/companies'} />
                                </MenuItem>
                            }

                            {userDetails.roles !== 'USER' &&
                                <MenuItem icon={<User />}>
                                    Users
                                </MenuItem>
                            }
                            <MenuItem icon={<Lock />}>Change Password</MenuItem>

                            {userDetails.client_code && userDetails.client_code.toLowerCase() === 'icl' &&
                                <MenuItem
                                    icon={<Database />}
                                    className={location.pathname.includes('/brands') && 'selected-menu-item'}
                                >
                                    Brand Database
                                    <Link to={'/brands'} />
                                </MenuItem>
                            }

                            <MenuItem
                                className={location.pathname.includes('/attributes') && 'selected-menu-item'}
                                icon={<Mapping />}
                            >
                                Attribute Mapping
                                <Link to={'/attributes'} />
                            </MenuItem>
                        </Menu>
                    </SidebarContent>
                    <SidebarFooter className='mt-auto'>
                        <MenuItem onClick={onLogoutClick} className='pro-icon-wrapper' icon={<Logout />}>Logout</MenuItem>
                    </SidebarFooter>
                </ProSidebar>
            </div>

            <div style={{ width: '100%', maxHeight: '100vh' }}>
                <Header
                    header={topic}
                    handleToggle={() => setToggled(!toggled)}
                    username={userDetails?.email?.split('@').length ? userDetails?.email?.split('@')[0] : userDetails.email}
                />
                <div className='p-4 layout-content-wrapper'>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Layout;