import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import Layout from "../layout";
import ClientTable from "../components/clients/ClientTable";
import ClientModal from "../components/clients/ClientModal";
import UserModal from "../components/clients/UserModal";

import {
    addClientData,
    addClientUserData,
    getAllClientData, getAllClientDataByUser,
    getAllClientUserData,
    updateClientData, updateClientUserData
} from "../redux/actions/clientActions";
import {JWTReader} from "../helpers/JWTReader";


const ClientsList = () => {

    const dispatch = useDispatch();
    const userDetails = JWTReader(localStorage.getItem('token'));

    const clientData = useSelector((el) => el.clientData.allClientData)
    const isLoading = useSelector((el) => el.loadingData.isTableLoading)

    const [isClientModalOpen, setIsClientModalOpen] = useState(false);
    const [isUserModalOpen, setIsUserModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedClient, setSlectedClient] = useState(null);
    const [clientState, setClientState] = useState({
        client: '',
        domain_name: '',
        client_code: '',
        add_limit: '',
        user_limit: ''
    })
    const [userState, setUserState] = useState({
        full_name: '',
        email: '',
        password: '',
        roles: '',
        isDisabled: false,
        add_product: false,
        add_catalog: false,
        download_report: false,
        is_demo: false
    })

    useEffect(()=>{
        if(userDetails.roles === 'MANAGER'){
            dispatch(getAllClientDataByUser(userDetails.client_code))
        } else {
            dispatch(getAllClientData());
        }
    }, [])

    const onChangeHandler=(e, type)=>{
        const {name, value} = e.target;

        if(type === 'user'){
            setUserState({
                ...userState,
                [name]: e.target.type === 'checkbox' ? !userState[name] : value
            })
        } else {
            let valid = true;
            if(name.includes('limit') && value){
                if(!parseInt(value)){
                    valid = false;
                }
            }
            if(valid) {
                setClientState({
                    ...clientState,
                    [name]: value
                })
            }
        }
    }

    const onChangeHandlerSwitch=()=>{
        setUserState({
            ...userState,
            isDisabled: !userState.isDisabled
        })
    }

    const modalHandler=(type, isEdit, data)=>{
        setIsEdit(isEdit);

        if(data)
        setSlectedClient(data.client_code)

        if(type === 'user') {
            if(isEdit) {
                setUserState({
                    ...userState,
                    ...data,
                    isDisabled: data.status ? data.status.toLowerCase() !== 'active' : false,
                    status: data.status ? data.status : 'ACTIVE'
                })
            }
            setIsUserModalOpen(true)
        }
        else {
            setIsClientModalOpen(true)
            setClientState({
                ...clientState,
                ...data
            })
        }
    }

    const toggleModal=(type)=>{
        setIsEdit(false)
        setSlectedClient(null)
        if(type === 'user') {
            setIsUserModalOpen(false)
            setUserState({
                full_name: '',
                username: '',
                password: '',
                roles: '',
                isDisabled: false,
                add_product: false,
                add_catalog: false,
                download_report: false,
                is_demo: false
            })
        }
        else {
            setIsClientModalOpen(false)
            setClientState({
                client: '',
                domain_name: '',
                client_code: '',
                add_limit: '',
                user_limit: ''
            })
        }
    }

    const submitHandler=(e, type)=>{
        e.preventDefault();

        if(type === 'user'){
            let data = userState;
            data.email = data.email.toLowerCase();

            if(!userState.roles){
                data.roles = 'USER'
            }

            if(isEdit){
                let disable = null;

                let prev = clientData.find((el)=> el.client_code === selectedClient)?.users.find((el)=> el.email === userState.email)?.status

                if(prev === 'ACTIVE' && userState.isDisabled) disable = true;
                else if(prev === 'DISABLED' && !userState.isDisabled) disable = false;

                dispatch(updateClientUserData(data, selectedClient, disable))
            } else {
                dispatch(addClientUserData(data, selectedClient, userDetails.roles === 'MANAGER' ? 2 : 1))
            }
        } else {
            if(isEdit){
                dispatch(updateClientData(clientState, null))
            } else {
                dispatch(addClientData(clientState, null))
            }
        }

        toggleModal(type)
    }

    const getClientUserData=(id)=>{
        dispatch(getAllClientUserData(id))
    }

    return (
        <Layout topic={'Client List'}>
            <div className='container mt-2'>
                <div>
                    <ClientTable
                        getClientUserData={getClientUserData}
                        clientData={clientData}
                        modalHandler={modalHandler}
                        isLoading={isLoading}
                        client_code={userDetails.roles === 'MANAGER' ? userDetails.client_code : null}
                    />
                </div>
            </div>
            <ClientModal
                submitHandler={(e)=> submitHandler(e, 'client')}
                formState={clientState}
                onChangeHandler={(e) =>onChangeHandler(e, 'client')}
                isEdit={isEdit}
                toggleModal={()=> toggleModal('client')}
                modalShow={isClientModalOpen}
            />

            <UserModal
                submitHandler={(e)=> submitHandler(e, 'user')}
                formState={userState}
                onChangeHandler={(e) =>onChangeHandler(e, 'user')}
                isEdit={isEdit}
                toggleModal={()=> toggleModal('user')}
                modalShow={isUserModalOpen}
                onChangeHandlerSwitch={onChangeHandlerSwitch}
            />
        </Layout>
    )
}

export default ClientsList;