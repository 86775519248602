import { saveAs } from 'file-saver';

import * as jobActionTypes from '../actionTypes/jobActionTypes'
import * as catalogActionTypes from "../actionTypes/catalogActionTypes";

import request from '../../helpers/requestHelper';


export const getAllJobDetails = (isInitial, isVerification, isForeign) => async dispatch => {
    dispatch({ type: jobActionTypes.GET_ALL_JOB_DATA_REQUEST, payload: isInitial })

    try {
        const res = await request({
            url: `/${isForeign ? "int_jobs" : "jobs"}/${isVerification ? '?job_type=attribute' : ''}`,
            auth: true,
            method: 'GET',
        });
        console.log("jobs res: ", res);
        dispatch({
            type: jobActionTypes.GET_ALL_JOB_DATA_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: jobActionTypes.GET_ALL_JOB_DATA_FAILED,
            payload: err?.data ? err.data : err
        });
    }
}

export const getSingleJobDetails = (id, isVerification, isForeign) => async dispatch => {
    if (!isVerification) dispatch({ type: jobActionTypes.GET_SINGLE_JOB_DATA_REQUEST })

    try {
        const res = await request({
            url: `/${isForeign ? "int_jobs" : "jobs"}/jobinfo?jobid=${id}${(isVerification ? "&job_type=attribute" : "")}`,
            auth: true,
            method: 'GET',
        });

        if (isVerification) {
            return res;
        } else {
            dispatch({
                type: jobActionTypes.GET_SINGLE_JOB_DATA_SUCCEED,
                payload: res
            });
        }
    } catch (err) {
        dispatch({
            type: jobActionTypes.GET_SINGLE_JOB_DATA_FAILED,
            payload: err?.data ? err.data : err
        });
    }
}

export const getJobProductDetails = (id, isVerification, isForeign) => async dispatch => {
    if (!isVerification) dispatch({ type: jobActionTypes.GET_JOB_PRODUCT_DATA_REQUEST })

    try {
        const res = await request({
            url: `/${isForeign ? "int_products" : "products"}/?pid=${id}${isVerification ? '&job_type=attribute' : ''}`,
            auth: true,
            method: 'GET',
        });

        if (isVerification) {
            return res;
        } else {
            dispatch({
                type: jobActionTypes.GET_JOB_PRODUCT_DATA_SUCCEED,
                payload: res
            });
        }
    } catch (err) {
        if (isVerification) {
            return err;
        } else {
            dispatch({
                type: jobActionTypes.GET_JOB_PRODUCT_DATA_FAILED,
                payload: err?.data ? err.data : err
            });
        }
    }
}


export const submitJobCSV = (file) => async dispatch => {
    dispatch({ type: jobActionTypes.GET_JOB_FILE_HEADERS_REQUEST });

    let data = new FormData();
    data.append('file', file);


    try {
        let res = await request({
            url: `/header_mapping/`,
            auth: true,
            method: 'POST',
            data: data
        });

        dispatch({
            type: jobActionTypes.GET_JOB_FILE_HEADERS_SUCCEED,
            payload: res
        });

    } catch (err) {
        dispatch({
            type: jobActionTypes.GET_JOB_FILE_HEADERS_FAILED,
            payload: err?.data ? err.data : err
        });
    }
}


export const createJob = (file, mappings, isForeign) => async dispatch => {
    dispatch({ type: jobActionTypes.SUBMIT_JOB_FILE_REQUEST });

    let data = new FormData();
    data.append('file', file);
    data.append('mapping', JSON.stringify(mappings));

    try {
        let res = await request({
            url: `/${isForeign ? "int_jobs" : "jobs"}/`,
            auth: true,
            method: 'POST',
            data: data
        });

        res.status = "OK"
        dispatch({
            type: jobActionTypes.SUBMIT_JOB_FILE_SUCCEED,
            payload: res
        });

        dispatch(getAllJobDetails(false, false, isForeign))
        return res.message;

    } catch (err) {
        dispatch({
            type: jobActionTypes.SUBMIT_JOB_FILE_FAILED,
            payload: err?.data ? err.data : err
        });

        return false;
    }
}

export const createJobViaImage = (files, gtin, isForeign) => async dispatch => {
    dispatch({ type: jobActionTypes.SUBMIT_JOB_FILE_REQUEST });

    let data = new FormData();
    data.append('gtin', gtin);

    for (let i = 0; i < files.length; i++) {
        data.append('files[]', files[i]);
    }

    try {
        let res = await request({
            url: `/${isForeign ? "int_image" : "image"}/`,
            auth: true,
            method: 'POST',
            data: data
        });

        res.status = "OK"
        dispatch({
            type: jobActionTypes.SUBMIT_JOB_FILE_SUCCEED,
            payload: res
        });

        dispatch(getAllJobDetails(false, false, isForeign))
        return res.message;

    } catch (err) {
        dispatch({
            type: jobActionTypes.SUBMIT_JOB_FILE_FAILED,
            payload: err?.data ? err.data : err
        });

        return false;
    }
}

export const getAllHeaderMappingData = (isVerification) => async dispatch => {
    dispatch({ type: jobActionTypes.GET_HEADERS_DATA_REQUEST })

    try {
        const res = await request({
            url: `/header_mapping/${isVerification ? '?job_type=attribute' : ''}`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: jobActionTypes.GET_HEADERS_DATA_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: jobActionTypes.GET_HEADERS_DATA_FAILED,
            payload: err?.data ? err.data : err
        });
    }
}

export const downloadJobData = (id, type, isVerification, isForeign) => async dispatch => {

    try {
        const res = await request({
            url: `/${isForeign ? "int_jobs" : "jobs"}/download?jobid=${id}&file_type=${type}${isVerification ? '&job_type=attribute' : ''}`,
            auth: true,
            method: 'GET',
            responseType: 'arraybuffer'
        });

        let name = `${id}.${type}`
        const blob = new Blob([res], {
            type: `application/${type}`,
        });
        saveAs(blob, name)

    } catch (err) {
        dispatch({
            type: catalogActionTypes.GET_SINGLE_CATALOG_DATA_FAILED,
            payload: err?.data ? err.data : err
        });
    }
}