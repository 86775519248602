import {Modal, ModalBody, Button, ModalHeader} from "reactstrap";
import {useState} from "react";

import {ReactComponent as Close} from '../assets/Close.svg'
import FileUpload from "./FileUpload";

function Brand_AttributeUploadModal(props) {

    const [fileState, setFileState] = useState([])

    const toggleModal=()=>{
        setFileState([]);
        props.toggleModal();
    }

    const onSubmit=()=>{
        props.onUploadDataset(fileState[0])
        setFileState([])
    }

    return (
        <div>
            <Modal
                size='xl'
                isOpen={props.modalShow}
                centered
                className="client-modal brand-modal"
            >
                <ModalHeader className='m-2 me-5 ms-5 pb-4 ps-0 pe-0'>
                    <div>Upload {props.isBrand ? "Dataset" : "Attributes"}</div>
                    <div onClick={toggleModal} className='cursor-pointer'><Close className='ms-auto'/></div>
                </ModalHeader>
                <ModalBody className='p-5 pt-3'>
                    <div className='mx-auto' style={{maxWidth:200}}>
                        <FileUpload
                            fileState={fileState}
                            setFileState={setFileState}
                            fileType={'csv'}
                        />
                    </div>
                    <button disabled={!fileState.length} onClick={onSubmit} className="button-custom btn mt-4">Upload</button>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default Brand_AttributeUploadModal;
