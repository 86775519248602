import {Modal, ModalBody, Button, ModalHeader} from "reactstrap";

import {ReactComponent as Close} from '../../assets/Close.svg'

function ClientModal(props) {

    return (
        <div>
            <Modal
                size='lg'
                isOpen={props.modalShow}
                centered
                className="client-modal"
            >
                <ModalHeader className='m-2 me-5 ms-5 pb-4 ps-0 pe-0'>
                    <div>{props.isEdit ? "Edit" : "Add New"} Client</div>
                    <div onClick={props.toggleModal} className='cursor-pointer'><Close className='ms-auto' /></div>
                </ModalHeader>
                <ModalBody className='p-5 pt-3'>
                    <form onSubmit={props.submitHandler}>
                        <div className='row'>
                            <div className='col-6'>
                                <label htmlFor='client' className='mb-2'>Client Name</label>
                                <input
                                    type='text'
                                    name='client'
                                    className='form-control'
                                    placeholder='Client Name'
                                    value={props.formState.client}
                                    onChange={props.onChangeHandler}
                                    required
                                    disabled={props.isEdit}
                                />
                            </div>

                            <div className='col-6'>
                                <label htmlFor='domain_name' className='mb-2'>Email Prefix</label>
                                <input
                                    type='text'
                                    name='domain_name'
                                    className='form-control'
                                    placeholder='Email'
                                    value={props.formState.domain_name}
                                    onChange={props.onChangeHandler}
                                    disabled={props.isEdit}
                                />
                            </div>
                        </div>

                        <div className='row mt-4'>
                            <div className='col-6'>
                                <label htmlFor='client_code' className='mb-2'>Customer Code</label>
                                <input
                                    type='text'
                                    name='client_code'
                                    className='form-control'
                                    placeholder='Customer Code'
                                    value={props.formState.client_code}
                                    onChange={props.onChangeHandler}
                                    disabled={props.isEdit}
                                />
                            </div>

                            <div className='col-6'>
                                <label htmlFor='add_limit' className='mb-2'>Process Limit/Month</label>
                                <input
                                    type='text'
                                    name='add_limit'
                                    className='form-control'
                                    placeholder='Process Limit'
                                    value={props.formState.add_limit}
                                    onChange={props.onChangeHandler}
                                />
                            </div>
                        </div>

                        <div className='mt-4'>
                            <label htmlFor='user_limit' className='mb-2'>User Limit</label>
                            <input
                                type='text'
                                name='user_limit'
                                className='form-control'
                                placeholder='User Limit'
                                value={props.formState.user_limit}
                                onChange={props.onChangeHandler}
                            />
                        </div>

                        <button type={"submit"} className="button-custom btn mt-4">Submit</button>
                    </form>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default ClientModal;
