import React, { useEffect, useRef, useState } from "react";

import Layout from "../layout";
import ImgUpload from "../components/ImgUpload";
import { Form } from "react-bootstrap";
import { logoDetectionRequest } from "../redux/actions/imageExtraActions";
import OverlaySpinner from "../components/OverlaySpinner";
import { useDispatch, useSelector } from "react-redux";
import capitalizeFirstLetter from "../helpers/capitalizeHelper";


const LogoDetector = () => {

    const dispatch = useDispatch();
    const imageDivRef = useRef(null)

    const gotError = useSelector((el) => el.imageExtraData.gotError);
    const isLoading = useSelector((el) => el.imageExtraData.isLoading);
    const bboxData = useSelector((el) => el.imageExtraData.bboxCoords);

    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const [processed, setProcessed] = useState([]);
    const [fileType, setFileType] = useState('url')
    const [inputUrl, setInputUrl] = useState('')
    const [logoFound, setLogoFound] = useState(false)

    useEffect(() => {
        if (bboxData) {
            getData();
        }
    }, [JSON.stringify(bboxData)])
    const onChangeHandler = (value) => {
        setFileType(value);
        setInputUrl('');
    }

    const onSubmitClick = () => {
        setIsSubmitClicked(true);
        dispatch(logoDetectionRequest(inputUrl));
        setTimeout(() => {
            imageDivRef.current?.scrollIntoView();
        }, 500)
    }

    const getData = () => {
        // console.log("bboxData: ", bboxData);
        // console.log("bboxData keys: ", Object.keys(bboxData));

        let processedData = [];

        if (bboxData?.message) {
            processedData.push({
                key: "status", value: bboxData.message
            });
            setLogoFound(false);
        } else {
            processedData.push({
                key: "status", value: bboxData.status,
            });
            let coords = bboxData.data[0].coordinates;
            console.log("coords, value: ", coords);
            processedData.push({ key: "BBox top left x-coordinate", value: (coords.x).toFixed(2) });
            processedData.push({ key: "BBox top left y-coordinate", value: (coords.y).toFixed(2) });
            processedData.push({ key: "BBox height", value: (coords.height).toFixed(2) });
            processedData.push({ key: "BBox width", value: (coords.width).toFixed(2) });
            setLogoFound(true);
        }

        setProcessed(processedData)
    }

    return (
        <Layout topic={"Logo Detector"}>
            <div className="img-enhancement-container">
                <div className='round-container p-4'>
                    <div className='d-flex mb-4 mt-2'>
                        <Form.Check
                            type='radio'
                            value='url'
                            name='file-type'
                            label={'URL'}
                            onChange={() => onChangeHandler("url")}
                            checked={fileType === 'url'}
                        />

                    </div>

                    <div className='mt-4 mt-md-5 d-flex flex-column flex-md-row align-items-start align-items-md-center'>
                        <label htmlFor='url' className='mb-2 normal-font text-nowrap ms-0 ms-md-2 me-5'>Enter URL</label>
                        <input
                            type='text'
                            name='url'
                            className='form-control'
                            placeholder='Enter URL...'
                            value={inputUrl}
                            onChange={(e) => setInputUrl(e.target.value)}
                            style={{ maxWidth: '700px' }}
                        />
                    </div>

                    <div className='mt-3 submit-btn text-end'>
                        <button
                            onClick={onSubmitClick}
                            disabled={(fileType === 'url' && !inputUrl)}
                            className="button-custom btn"
                        >
                            Submit
                        </button>
                    </div>
                </div>

                {isSubmitClicked &&
                    <div className='round-container mt-4 p-4 mb-5' ref={imageDivRef}>
                        <div className='sub-headers'>Processed Data</div>

                        <div className='position-relative processed-img-container'>
                            <OverlaySpinner isNormal={true} isLoading={isLoading} loadingText={"Processing. Please wait..."} />
                            {bboxData &&
                                <div className='text-center'>
                                    {gotError ?
                                        <div className='text-danger pt-3 normal-font'>There was an error while processing the image</div>
                                        :
                                        <div>
                                            <img src={inputUrl} style={{ maxWidth: "400px" }} className="mb-4" />
                                            <div className='attribute-mapping-container mt-4 mt-md-5'>

                                                {processed.slice(0, 1).map((el, index) => {
                                                    return (
                                                        // <div className='attribute-mapping-headers d-flex align-items-center'>
                                                        //     {/* <div>{capitalizeFirstLetter(el.key)}</div> */}
                                                        //     <div>{el.value}</div>
                                                        // </div>
                                                        <center><div><strong>{el.value}</strong></div></center>
                                                    )
                                                })}

                                                {logoFound &&
                                                    <>
                                                        <div className='attribute-mapping-headers d-flex justify-content-evenly mb-4 mt-5'>
                                                            <div>Attribute</div>
                                                            <div>Coordinates</div>
                                                        </div>

                                                        {processed.slice(1).map((el, index) => {
                                                            return (
                                                                <div className='attribute-mapping-row d-flex align-items-center'>
                                                                    <div>{capitalizeFirstLetter(el.key)}</div>
                                                                    <div>{el.value}</div>
                                                                </div>
                                                            )
                                                        })}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                }

            </div>
        </Layout>
    )
}

export default LogoDetector;