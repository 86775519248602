import * as imageExtraActionTypes from '../actionTypes/imageExtraActionTypes'

import request from '../../helpers/requestHelper';
import axios from "axios";

export const imageEnhancementRequest = (image, onlyBackground) => async dispatch => {
    dispatch({ type: imageExtraActionTypes.IMAGE_ENHANCEMENT_REQUEST })

    let data = new FormData();
    data.append('files[]', image);
    data.append('ONLY_BG_REMOVAL', onlyBackground);

    try {
        const res = await request({
            url: `/water/water`,
            auth: true,
            method: 'POST',
            data: data,
            responseType: 'blob'
        });

        dispatch({
            type: imageExtraActionTypes.IMAGE_ENHANCEMENT_SUCCEED,
            payload: URL.createObjectURL(res.data)
        });

    } catch (err) {
        dispatch({
            type: imageExtraActionTypes.IMAGE_ENHANCEMENT_FAILED,
            payload: err?.data ? err.data : err
        });
    }
}

export const logoDetectionRequest = (image_url) => async dispatch => {
    dispatch({ type: imageExtraActionTypes.LOGO_DETECTION_REQUEST })

    let data = {};
    if (image_url) {
        data.image_url = image_url;
    }
    try {
        const res = await request({
            url: `/logo_detector/`,
            auth: true,
            method: 'POST',
            data: data,
        });

        dispatch({
            type: imageExtraActionTypes.LOGO_DETECTION_SUCCEED,
            payload: res.brand
        });

    } catch (err) {
        dispatch({
            type: imageExtraActionTypes.LOGO_DETECTION_FAILED,
            payload: err?.data ? err.data : err
        });
    }
}

export const getImageAttributes = (image, url) => async dispatch => {
    dispatch({ type: imageExtraActionTypes.GET_IMAGE_ATTRIBUTES_REQUEST })

    let data;

    if (url) {
        data = {};
        data.image_url = url;
    } else {
        data = new FormData();
        data.append('files[]', image);
    }

    try {
        const res = await request({
            url: `/furniture/`,
            auth: true,
            method: 'POST',
            data: data,
        });

        dispatch({
            type: imageExtraActionTypes.GET_IMAGE_ATTRIBUTES_SUCCEED,
            payload: res?.brand?.prediction
        });

    } catch (err) {
        dispatch({
            type: imageExtraActionTypes.GET_IMAGE_ATTRIBUTES_FAILED,
            payload: err?.data ? err.data : err
        });
    }
}

export const getAlcoholAttributes = (image, url) => async dispatch => {
    dispatch({ type: imageExtraActionTypes.GET_ALCOHOL_ATTRIBUTES_REQUEST })

    let data;

    if (url) {
        data = {};
        data.image_urls = [url];
    } else {
        data = new FormData();
        data.append('files[]', image);
    }

    try {
        const res = await request({
            url: `/alcohol/`,
            auth: true,
            method: 'POST',
            data: data,
        });

        let dataObj = res.output ? JSON.parse(res.output[0]) : null
        dispatch({
            type: imageExtraActionTypes.GET_ALCOHOL_ATTRIBUTES_SUCCEED,
            payload: dataObj ? [dataObj] : []
        });

    } catch (err) {
        dispatch({
            type: imageExtraActionTypes.GET_ALCOHOL_ATTRIBUTES_FAILED,
            payload: err?.data ? err.data : err
        });
    }
}

export const getFashionAttributes = (image, url) => async dispatch => {
    dispatch({ type: imageExtraActionTypes.GET_FASHION_ATTRIBUTES_REQUEST })

    let data;

    if (url) {
        data = {};
        data.x = [url];
    } else {
        data = new FormData();
        data.append('files[]', image);
    }

    try {
        const res = await request({
            url: `/fashioniq/`,
            auth: true,
            method: 'POST',
            data: data,
        });

        dispatch({
            type: imageExtraActionTypes.GET_FASHION_ATTRIBUTES_SUCCEED,
            payload: [res.data]
        });

    } catch (err) {
        dispatch({
            type: imageExtraActionTypes.GET_FASHION_ATTRIBUTES_FAILED,
            payload: err?.data ? err.data : err
        });
    }
}



// export default getImageAttributes;