import * as brandActionTypes from '../actionTypes/brandActionTypes'

import request from '../../helpers/requestHelper';

export const getAllBrandData = () => async dispatch => {
    dispatch({type: brandActionTypes.GET_BRAND_DATA_REQUEST})

    try {
        const res = await request({
            url: `/brands/`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: brandActionTypes.GET_BRAND_DATA_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: brandActionTypes.GET_BRAND_DATA_FAILED,
            payload: err?.data ? err.data : err
        });
    }
}


export const uploadBrandDataset = (file) => async dispatch => {
    dispatch({type: brandActionTypes.UPLOAD_BRAND_DATA_REQUEST})

    let data = new FormData();
    data.append('file', file);

    try {
        const res = await request({
            url: `/brands/`,
            auth: true,
            method: 'POST',
            data: data
        });

        dispatch({
            type: brandActionTypes.UPLOAD_BRAND_DATA_SUCCEED,
            payload: res
        });

        dispatch(getAllBrandData())
        return "You have successfully uploaded your brands dataset";

    } catch (err) {
        dispatch({
            type: brandActionTypes.UPLOAD_BRAND_DATA_FAILED,
            payload: err?.data ? err.data : err
        });
    }
}