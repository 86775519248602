import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Form } from "react-bootstrap";

import Layout from "../layout";
import JobTable from "../components/gtin_job/JobTable";
import FileUpload from "../components/gtin_job/FileUpload_gtin";

import {
    createJob,
    downloadJobData,
    getAllJobDetails,
} from "../redux/actions/gtinActions";

import { JWTReader } from "../helpers/JWTReader";
import JobDetails from "../components/gtin_job/JobDetails";

const GtinFinder = () => {

    const dispatch = useDispatch();
    const jobDetails = useSelector((el) => el.jobData.allJobData)


    const [formState, setFormState] = useState({
        gtin: '',
        url: ''
    })
    const [fileState, setFileState] = useState([])
    const [fileType, setFileType] = useState('csv')
    const [alert, setAlert] = useState('')


    useEffect(() => {
        if (localStorage.getItem('token')) {
            dispatch(getAllJobDetails(true));
        }
    }, [])


    useEffect(() => {
        const interval = setInterval(() => {
            if (localStorage.getItem('token'))
                dispatch(getAllJobDetails());
        }, 10000);
        return () => clearInterval(interval);
    }, [])

    const onSubmitClick = () => {
        setFileState([]);
        dispatch(createJob(fileState[0]))
            .then((res) => {
                if (res) {
                    setAlert(res)
                    setFileState([])
                    setTimeout(() => {
                        setAlert('')
                    }, 8000)
                }
            })
    }

    const onChangeHandler = (e) => {
        const { value } = e.target;
        setFileType(value)
        setFileState([])
    }

    const onDownloadJob = (id, type) => {
        dispatch(downloadJobData(id, type))
    }

    return (
        <Layout topic={"New Job"}>
            <div>
                {alert && <Alert style={{
                    position: 'fixed',
                    height: 70,
                    width: '100%',
                    top: 0,
                    left: 0,
                    zIndex: 1010,
                    textAlign: 'right',
                    borderRadius: 0
                }} variant={'success'}
                    className='normal-font d-flex align-items-center justify-content-center'>{alert}</Alert>}
                <div className='container'>
                    <JobDetails
                        jobDetails={jobDetails}
                    />

                    <div className='mt-4'>
                        <div className='sub-headers'>New Job</div>
                        <div className='round-container pt-4 pb-4 row m-0 mt-2'>

                            {/* This is file upload check boxes*/}
                            <div className='d-flex mb-5'>
                                <Form.Check
                                    type='radio'
                                    value='csv'
                                    name='file-type'
                                    label={'.CSV/.XLS'}
                                    onChange={onChangeHandler}
                                    checked={fileType === 'csv'}
                                    className='me-5'
                                />

                            </div>

                            <div
                                className='d-flex align-items-center flex-column flex-md-row' >
                                <div>
                                    {/* This is upload component */}
                                    <FileUpload
                                        fileState={fileState}
                                        setFileState={setFileState}
                                        fileType={fileType}
                                        formState={formState}
                                        setFormState={setFormState}
                                    />
                                </div>
                                <div className='ms-0 mt-2 mt-md-0 ms-md-5'>
                                    <div style={{ width: 150 }}>
                                        <button onClick={() => onSubmitClick()}
                                            disabled={fileType === 'csv' ? !fileState.length : fileType === 'url' ? !formState.gtin : (!formState.gtin || !fileState.length)}
                                            className="button-custom btn">Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='mt-4'>
                        <div className='sub-headers'>Jobs</div>
                        <div className='mt-2'>
                            <JobTable
                                client_code={JWTReader(localStorage.getItem('token'))?.client_code}
                                data={jobDetails.data}
                                onDownloadJob={onDownloadJob}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default GtinFinder;