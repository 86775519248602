import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import Layout from "../layout";
import Brand_AttributeUploadModal from "../components/Brand_AttributeUploadModal";

import {getAllBrandData, uploadBrandDataset} from "../redux/actions/brandActions";

import {ReactComponent as Plus} from '../assets/Plus.svg'
import capitalizeFirstLetter from "../helpers/capitalizeHelper";
import {Alert} from "react-bootstrap";

const BrandsList = () => {

    const dispatch = useDispatch();
    const brandDataList = useSelector((el) => el.brandData.brandsList)
    const brandDataCount = useSelector((el) => el.brandData.brand_count)

    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [alert, setAlert] = useState('')

    useEffect(() => {
        if (localStorage.getItem('token')) {
            dispatch(getAllBrandData());
        }
    }, [])

    const onUploadDataset = (file) => {
        setIsUploadModalOpen(false);
        dispatch(uploadBrandDataset(file))
            .then((res) => {
                if (res) {
                    setAlert(res)
                    setTimeout(() => {
                        setAlert('')
                    }, 8000)
                }
            })
    }

    return (
        <Layout topic={"Brand Database"}>
            {alert &&
                <Alert
                    style={{
                        position: 'fixed',
                        height: 70,
                        width: '100%',
                        top: 0,
                        left: 0,
                        zIndex: 1010,
                        textAlign: 'right',
                        borderRadius: 0
                    }}
                    variant={'success'}
                    className='normal-font d-flex align-items-center justify-content-center'>
                    {alert}
                </Alert>
            }
            <div className='mt-2 round-container pt-4 pb-4 row mt-2 mx-auto brands-data-container'>
                <div style={{fontWeight: 700}} className='mb-3 sub-headers'>Brands</div>
                <div className='brands-top mt-4'>
                    <div>{brandDataCount}</div>
                    <div>Brands</div>
                </div>

                <div className='mt-4 mb-4'>
                    <button onClick={() => setIsUploadModalOpen(true)}
                            className="button-custom btn ps-4 pe-4 pt-3 pb-3">
                        <Plus className='me-2' color={'white'}/>
                        Upload Brand Dataset
                    </button>
                </div>

                <div className='brands-details-container'>
                    {brandDataList.map((el, index) => (
                        <div className='brands-row d-flex align-items-center'
                             style={index === 0 ? {borderTop: 'none'} : {}}>{capitalizeFirstLetter(el)}</div>
                    ))}
                </div>
            </div>

            <Brand_AttributeUploadModal
                isBrand={true}
                modalShow={isUploadModalOpen}
                toggleModal={() => setIsUploadModalOpen(false)}
                onUploadDataset={onUploadDataset}
            />
        </Layout>
    )
}

export default BrandsList;