import * as catalogActionTypes from '../actionTypes/catalogActionTypes'

import produce from "immer";

const initialState = {
    allCatalogData: [],
    singleCatalogData: {},
};

const catalogData = produce(
    (state = initialState, action) => {
        const { type, payload } = action;

        switch (type) {

            case catalogActionTypes.GET_ALL_CATALOG_DATA_SUCCEED: {
                state.allCatalogData = payload.data;
                return state;
            }
            case catalogActionTypes.GET_SINGLE_CATALOG_DATA_SUCCEED: {
                let obj = payload.data;
                if(obj.images && !Array.isArray(obj.images)){
                    obj.images = obj.images.split(',')
                }

                state.singleCatalogData = obj;

                return state;
            }
            case catalogActionTypes.GET_SINGLE_CATALOG_DATA_REQUEST: {
                state.allCatalogData = {};
                return state;
            }

            default: {
                return state;
            }
        }
    }
);

export default catalogData;
