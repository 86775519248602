import moment from "moment";
import {useDispatch} from "react-redux";

import JobDataTable from "./JobDataTable";

import capitalizeFirstLetter from "../../helpers/capitalizeHelper";
import {downloadJobData} from "../../redux/actions/jobActions";

const JobDetailDiv=({jobState, onClickProduct, isVerification})=>{

    const dispatch = useDispatch();

    const onDownloadFiles = async (id, type) => {
        dispatch(downloadJobData(id, type, true))
    }

    return(
        <div>
            <div
                className='round-container pt-4 pb-4 ps-5 pe-5 m-0 mt-4 d-block d-md-flex justify-content-between job-detail-container'>
                <div>
                    <div>Job ID:</div>
                    <div>{jobState.jobid}</div>
                </div>

                <div>
                    <div>Date Submitted:</div>
                    <div>{moment(jobState.date).format("DD/MM/YYYY")}</div>
                </div>

                <div>
                    <div>No of Records:</div>
                    <div>{jobState.noOfRecords}</div>
                </div>

                <div>
                    <div>Status:</div>
                    <div>{capitalizeFirstLetter(jobState.status)}</div>
                </div>

                {(jobState.status === "completed") &&
                    <div className='download-column'>
                        <div className='d-flex justify-content-center'>
                            <div onClick={() => onDownloadFiles(jobState.jobid, 'csv')}
                                 className='color-secondary me-3'>CSV
                            </div>
                            <div onClick={() => onDownloadFiles(jobState.jobid, 'json')}
                                 className='color-secondary me-3'>JSON
                            </div>
                            <div onClick={() => onDownloadFiles(jobState.jobid, 'xls')}
                                 className='color-secondary'>XLS
                            </div>
                        </div>
                    </div>
                }
            </div>

            <div className='mt-4'>
                <JobDataTable
                    jobid={jobState.id}
                    data={jobState && jobState.products ? jobState.products : []}
                    isVerification={isVerification}
                    onClickProduct={onClickProduct}
                />
            </div>
        </div>
    )
}

export default JobDetailDiv;