export const GET_ALL_JOB_DATA_REQUEST = "GET_ALL_JOB_DATA_REQUEST";
export const GET_ALL_JOB_DATA_SUCCEED = "GET_ALL_JOB_DATA_SUCCEED";
export const GET_ALL_JOB_DATA_FAILED = "GET_ALL_JOB_DATA_FAILED";

export const GET_SINGLE_JOB_DATA_REQUEST = "GET_SINGLE_JOB_DATA_REQUEST";
export const GET_SINGLE_JOB_DATA_SUCCEED = "GET_SINGLE_JOB_DATA_SUCCEED";
export const GET_SINGLE_JOB_DATA_FAILED = "GET_SINGLE_JOB_DATA_FAILED";

export const GET_JOB_PRODUCT_DATA_REQUEST = "GET_JOB_PRODUCT_DATA_REQUEST";
export const GET_JOB_PRODUCT_DATA_SUCCEED = "GET_JOB_PRODUCT_DATA_SUCCEED";
export const GET_JOB_PRODUCT_DATA_FAILED = "GET_JOB_PRODUCT_DATA_FAILED";

export const SUBMIT_JOB_FILE_REQUEST = "SUBMIT_JOB_FILE_REQUEST";
export const SUBMIT_JOB_FILE_SUCCEED = "SUBMIT_JOB_FILE_SUCCEED";
export const SUBMIT_JOB_FILE_FAILED = "SUBMIT_JOB_FILE_FAILED";

export const GET_JOB_FILE_HEADERS_REQUEST = "GET_JOB_FILE_HEADERS_REQUEST";
export const GET_JOB_FILE_HEADERS_SUCCEED = "GET_JOB_FILE_HEADERS_SUCCEED";
export const GET_JOB_FILE_HEADERS_FAILED = "GET_JOB_FILE_HEADERS_FAILED";

export const GET_HEADERS_DATA_REQUEST = "GET_HEADERS_DATA_REQUEST";
export const GET_HEADERS_DATA_SUCCEED = "GET_HEADERS_DATA_SUCCEED";
export const GET_HEADERS_DATA_FAILED = "GET_HEADERS_DATA_FAILED";