import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { useNavigate } from "react-router-dom";
import moment from "moment";

import capitalizeFirstLetter from "../../helpers/capitalizeHelper";

import SortIcon from '../../assets/sort.png'

const SIZE_PER_PAGE = 10;

const JobTable = (props) => {

    const columns1 = [{
        dataField: 'jobid',
        text: 'ID',
        sort: true,
        style: {
            cursor: 'pointer',
            textDecoration: 'underline'
        },
        sortCaret: (order, column) => {
            return (
                <span>
                    <img src={SortIcon} style={{ width: 16, marginLeft: 5, height: 12, fill: "gray" }} />
                </span>
            );
        }
    }, {
        dataField: 'client_code',
        text: 'Client',
        sort: true,
        sortCaret: () => {
            return (
                <span>
                    <img src={SortIcon} style={{ width: 16, marginLeft: 5, height: 12, fill: "gray" }} />
                </span>
            );
        }
    }]


    const columns2 = [{
        dataField: 'jobid',
        text: 'ID',
        sort: true,
        style: {
            cursor: 'pointer',
            textDecoration: 'underline'
        },
        sortCaret: (order, column) => {
            return (
                <span>
                    <img src={SortIcon} style={{ width: 16, marginLeft: 5, height: 12, fill: "gray" }} />
                </span>
            );
        }
    }]

    const columns = [
        {
            dataField: 'input_file',
            text: 'File',
            style: { overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" },
            sort: true,
            sortCaret: () => {
                return (
                    <span>
                        <img src={SortIcon} style={{ width: 16, marginLeft: 5, height: 12, fill: "gray" }} />
                    </span>
                );
            }
        }, {
            dataField: 'submit_date',
            text: 'Date',
            sort: true,
            sortCaret: () => {
                return (
                    <span>
                        <img src={SortIcon} style={{ width: 16, marginLeft: 5, height: 12, fill: "gray" }} />
                    </span>
                );
            },
            sortFunc: (a, b, order) => {
                let aa = moment(a).valueOf();
                let bb = moment(b).valueOf();
                if (order === 'asc') {
                    return bb - aa;
                }
                return aa - bb;
            },
            formatter: (rowContent, row) => {
                return moment(row.submit_date).local(true).format("DD/MM/YYYY hh:mm:A")
            }
        },
        // {
        //     dataField: 'completed_time',
        //     text: 'Completion Date',
        //     sort: true,
        //     sortCaret: () => {
        //         return (
        //             <span>
        //                 <img src={SortIcon} style={{ width: 16, marginLeft: 5, height: 12, fill: "gray" }} />
        //             </span>
        //         );
        //     },
        //     sortFunc: (a, b, order, field, row1, row2) => {
        //         let aa = row1.completed_time ? moment(row1.completed_time).valueOf() : 0;
        //         let bb = row2.completed_time ? moment(row2.completed_time).valueOf() : 0;
        //         if (order === 'asc') {
        //             return bb - aa;
        //         }
        //         return aa - bb;
        //     },
        //     formatter: (rowContent, row) => {
        //         return row.completed_time ? moment(row.completed_time).local(true).format("DD/MM/YYYY hh:mm:A") : "-"
        //     }
        // },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
            sortCaret: () => {
                return (
                    <span>
                        <img src={SortIcon} style={{ width: 16, marginLeft: 5, height: 12, fill: "gray" }} />
                    </span>
                );
            },
            formatter: (rowContent, row) => {
                return capitalizeFirstLetter(row.status)
            }
        }, {
            dataField: 'product_count',
            text: 'Records Processed',
            sort: true,
            sortCaret: () => {
                return (
                    <span>
                        <img src={SortIcon} style={{ width: 16, marginLeft: 5, height: 12, fill: "gray" }} />
                    </span>
                );
            },
            sortFunc: (a, b, order, field, row1, row2) => {
                let aa = row1.processed / row1.number_of_products;
                let bb = row2.processed / row2.number_of_products;
                if (order === 'asc') {
                    return bb - aa;
                }
                return aa - bb;
            },
            formatter: (rowContent, row) => {
                if (row.number_of_products) {
                    return <div>{row.processed ? row.processed : 0}/{row.number_of_products}</div>
                } else {
                    return <div>-</div>
                }
            }
        }, {
            dataField: '',
            text: 'Download',
            classes: 'download-column',
            formatter: (rowContent, row) => {
                if (row.status !== "completed") {
                    return (
                        <div className='d-flex justify-content-center'></div>
                    )
                } else {
                    return (
                        <div className='d-flex justify-content-center'>
                            <div onClick={() => onDownloadFiles(row.jobid, 'csv')}
                                className='download-btn color-secondary me-3'>CSV
                            </div>
                            <div onClick={() => onDownloadFiles(row.jobid, 'xls')}
                                 className='download-btn color-secondary me-3'>XLS
                            </div>
                        </div>
                    )
                }
            }
        }];

    const navigate = useNavigate();

    const handleNextPage = ({ page, onPageChange }) => () => {
        onPageChange(page + 1);
    }

    const handlePrevPage = ({ page, onPageChange }) => () => {
        onPageChange(page - 1);
    }

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            if (!e.target.className.includes('download-btn')) {
                if (props.isVerification) {
                    navigate(`/attribute-verification/${row.jobid}`);
                } else {
                    navigate(`/jobs/${row.jobid}`);
                }
            }
        },
    };

    const onDownloadFiles = (id, type) => {
        props.onDownloadJob(id, type)
    }

    const options = {
        custom: true,
        totalSize: props.data.length,
        sizePerPage: SIZE_PER_PAGE
    };


    return (

        <div style={{ marginBottom: 50, position: 'relative' }}>
            <PaginationProvider
                pagination={paginationFactory(options)}
            >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <div style={{
                            background: "#FFF",
                            borderRadius: "16px 16px 0 0",
                            padding: "0 20px",
                            overflowX: "auto"
                        }}>
                            <BootstrapTable
                                keyField="jobid"
                                data={props.data}
                                columns={props.client_code && props.client_code.toLowerCase() === 'icl' ? [...columns1, ...columns] : [...columns2, ...columns]}
                                // rowEvents={rowEvents}
                                classes="job-table-class"
                                headerClasses="job-table-header-class"
                                bodyClasses="job-table-body-class"
                                {...paginationTableProps}
                            />
                            <div className='text-end pb-3' style={{
                                marginTop: "-17px",
                                position: 'absolute',
                                right: 0,
                                background: "#FFF",
                                borderRadius: "0 0 16px 16px",
                                width: "100%"
                            }}>
                                <div className='pe-4'>
                                    {paginationProps.page !== 1 && <button className="btn normal-font color-secondary"
                                        onClick={handlePrevPage(paginationProps)}>Prev</button>}
                                    {((paginationProps.totalSize + (SIZE_PER_PAGE - (paginationProps.totalSize % SIZE_PER_PAGE))) / SIZE_PER_PAGE) !== paginationProps.page &&
                                        <button className="btn normal-font color-secondary"
                                            onClick={handleNextPage(paginationProps)}>Next</button>}
                                </div>
                            </div>
                        </div>
                    )
                }
            </PaginationProvider>
        </div>
    )
}

export default JobTable;