export const GET_ATTRIBUTE_DATA_REQUEST = "GET_ATTRIBUTE_DATA_REQUEST";
export const GET_ATTRIBUTE_DATA_SUCCEED = "GET_ATTRIBUTE_DATA_SUCCEED";
export const GET_ATTRIBUTE_DATA_FAILED = "GET_ATTRIBUTE_DATA_FAILED";

export const GET_ATTRIBUTE_DATA_FROM_FILE_REQUEST = "GET_ATTRIBUTE_DATA_FROM_FILE_REQUEST";
export const GET_ATTRIBUTE_DATA_FROM_FILE_SUCCEED = "GET_ATTRIBUTE_DATA_FROM_FILE_SUCCEED";
export const GET_ATTRIBUTE_DATA_FROM_FILE_FAILED = "GET_ATTRIBUTE_DATA_FROM_FILE_FAILED";

// export const GET_ATTRIBUTE_DATA_FROM_IMAGE_REQUEST = "GET_ATTRIBUTE_DATA_FROM_IMAGE_REQUEST";
// export const GET_ATTRIBUTE_DATA_FROM_IMAGE_SUCCEED = "GET_ATTRIBUTE_DATA_FROM_IMAGE_SUCCEED";
// export const GET_ATTRIBUTE_DATA_FROM_IMAGE_FAILED = "GET_ATTRIBUTE_DATA_FROM_IMAGE_FAILED";

// export const GET_ATTRIBUTE_VERIFICATION_DATA_REQUEST = "GET_ATTRIBUTE_VERIFICATION_DATA_REQUEST";
// export const GET_ATTRIBUTE_VERIFICATION_DATA_SUCCEED = "GET_ATTRIBUTE_VERIFICATION_DATA_SUCCEED";
// export const GET_ATTRIBUTE_VERIFICATION_DATA_FAILED = "GET_ATTRIBUTE_VERIFICATION_DATA_FAILED";

export const ATTRIBUTE_UPDATE_REQUEST = "ATTRIBUTE_UPDATE_REQUEST";
export const ATTRIBUTE_UPDATE_SUCCEED = "ATTRIBUTE_UPDATE_SUCCEED";
export const ATTRIBUTE_UPDATE_FAILED = "ATTRIBUTE_UPDATE_FAILED";

export const UPLOAD_ATTRIBUTE_DATA_REQUEST = "UPLOAD_ATTRIBUTE_DATA_REQUEST";
export const UPLOAD_ATTRIBUTE_DATA_FAILED = "UPLOAD_ATTRIBUTE_DATA_FAILED";

export const RESET_ATTRIBUTE_VERIFY_SUCCEED = "RESET_ATTRIBUTE_VERIFY_SUCCEED";
export const RESET_ALL_ATTRIBUTE_VERIFY_SUCCEED = "RESET_ALL_ATTRIBUTE_VERIFY_SUCCEED";

export const GET_JOB_DETAILS_FOR_ATTRIBUTE_VERIFICATION_REQUEST = "GET_JOB_DETAILS_FOR_ATTRIBUTE_VERIFICATION_REQUEST";
export const GET_JOB_DETAILS_FOR_ATTRIBUTE_VERIFICATION_SUCCEED = "GET_JOB_DETAILS_FOR_ATTRIBUTE_VERIFICATION_SUCCEED";
export const GET_JOB_DETAILS_FOR_ATTRIBUTE_VERIFICATION_FAILED = "GET_JOB_DETAILS_FOR_ATTRIBUTE_VERIFICATION_FAILED";

export const GET_PRODUCT_DETAILS_FOR_ATTRIBUTE_VERIFICATION_REQUEST = "GET_PRODUCT_DETAILS_FOR_ATTRIBUTE_VERIFICATION_REQUEST";
export const GET_PRODUCT_DETAILS_FOR_ATTRIBUTE_VERIFICATION_SUCCEED = "GET_PRODUCT_DETAILS_FOR_ATTRIBUTE_VERIFICATION_SUCCEED";
export const GET_PRODUCT_DETAILS_FOR_ATTRIBUTE_VERIFICATION_FAILED = "GET_PRODUCT_DETAILS_FOR_ATTRIBUTE_VERIFICATION_FAILED";