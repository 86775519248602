import React from "react";
import Chart from 'react-apexcharts'

const PieChart=()=>{

    const options = {
        series: [44, 55, 13, 43],
        options: {
            chart: {
                width: 580,
                type: 'pie',
            },
            labels: ['Food & Beverages', 'Health & Beauty', 'Home & Garden', 'Others'],
            colors: ['#61A0A8', '#D48265', '#749F83', '#C23531']
        },
    };

    return(
        <div className='round-container p-4'>
            <div className='sub-headers'>Catalog Distribution Chart</div>
            <div id="chart">
                <Chart
                    options={options.options}
                    series={options.series}
                    type="pie"
                    width={'100%'}
                />
            </div>

        </div>
    )
};

export default PieChart;