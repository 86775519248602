import React from "react";
import capitalizeFirstLetter from "../helpers/capitalizeHelper";


const AttributeMappingTable=(props)=>{

    // const onChangeHandler=(e, item)=>{
    //     const {value} = e.target;
    //
    //     props.setMapState({
    //         ...props.mapState,
    //         [item]: value
    //     })
    //     props.setIsMapped(value !== '');
    // }

    return(
        <div className='attribute-mapping-container mt-4 mt-md-5'>
            <div className='attribute-mapping-headers d-flex justify-content-evenly mb-4'>
                <div>ICL Attribute</div>
                <div>Attribute</div>
            </div>

            {props.headers.map((el, index)=>{
                return(
                    <div className='attribute-mapping-row d-flex align-items-center'>
                        <div>{index + 1}</div>
                        <div>{capitalizeFirstLetter(el)}</div>
                        <div className={!props.userAttributes && 'text-center'}>{(props.userAttributes) ? props.userAttributes[el] : 'Client Attribute'}</div>
                    </div>
                )
            })}
        </div>
    )
}

export default AttributeMappingTable;