import * as attributeActionTypes from '../actionTypes/attributeActionTypes'

import produce from "immer";

const initialState = {
    attributeList: [],
    userAttributeList: null,
    childAttributeList: null,
    parentAttributeList: null,
    jobDetails: null,
    verifiedStatus: null
};

const attributeData = produce(
    (state = initialState, action) => {
        const { type, payload } = action;

        switch (type) {
            case attributeActionTypes.GET_ATTRIBUTE_DATA_SUCCEED: {
                state.attributeList = payload.icl_attribute;
                state.userAttributeList = payload.mapped_attribute;
                return state;
            }
            case attributeActionTypes.GET_PRODUCT_DETAILS_FOR_ATTRIBUTE_VERIFICATION_SUCCEED: {
                state.childAttributeList = payload.data;
                return state;
            }
            case attributeActionTypes.GET_ATTRIBUTE_DATA_FROM_FILE_SUCCEED: {
                state.parentAttributeList = payload.headers;
                state.jobDetails = payload.jobDetails;
                return state;
            }
            case attributeActionTypes.GET_JOB_DETAILS_FOR_ATTRIBUTE_VERIFICATION_SUCCEED: {
                state.jobDetails = payload;
                return state;
            }
            case attributeActionTypes.ATTRIBUTE_UPDATE_SUCCEED: {
                state.childAttributeList = null;
                return state;
            }
            case attributeActionTypes.RESET_ATTRIBUTE_VERIFY_SUCCEED: {
                state.parentAttributeList = null;
                state.jobDetails = null;
                state.verifiedStatus = null;
                return state;
            }

            case attributeActionTypes.GET_ATTRIBUTE_DATA_FROM_FILE_REQUEST: {
                state.parentAttributeList = null;
                state.jobDetails = null;
                return state;
            }

            default: {
                return state;
            }
        }
    }
);

export default attributeData;
