import {Modal, ModalBody, Button, ModalHeader} from "reactstrap";
import Switch from "react-switch";

import {ReactComponent as Close} from '../../assets/Close.svg'

import {roles} from "../../helpers/roleData";

function UserModal(props) {

    return (
        <div>
            <Modal
                size='lg'
                isOpen={props.modalShow}
                centered
                className="client-modal"
            >
                <ModalHeader className='m-2 me-5 ms-5 pb-4 ps-0 pe-0'>
                    <div>{props.isEdit ? "Edit" : "Add New"} User</div>
                    <div onClick={props.toggleModal} className='cursor-pointer'><Close className='ms-auto'/></div>
                </ModalHeader>
                <ModalBody className='p-5 pt-3'>
                    <form onSubmit={props.submitHandler}>

                        <div>
                            <label htmlFor='full_name' className='mb-2'>Full Name</label>
                            <input
                                type='text'
                                name='full_name'
                                className='form-control'
                                placeholder='Full Name'
                                value={props.formState.full_name}
                                onChange={props.onChangeHandler}
                                required
                            />
                        </div>

                        <div className='row'>
                            <div className='mt-4 col-md-6'>
                                <label htmlFor='email' className='mb-2'>Email</label>
                                <input
                                    type='email'
                                    name='email'
                                    className='form-control'
                                    placeholder='Username (Email)'
                                    value={props.formState.email}
                                    onChange={props.onChangeHandler}
                                    required
                                    disabled={props.isEdit}
                                />
                            </div>

                            <div className='mt-4 col-md-6'>
                                <label htmlFor='password' className='mb-2'>Password</label>
                                <input
                                    type='password'
                                    name='password'
                                    className='form-control'
                                    placeholder='Password'
                                    value={props.formState.password}
                                    onChange={props.onChangeHandler}
                                    required={!props.isEdit}
                                />
                            </div>
                        </div>


                        <div className='row'>
                            <div className={'mt-4 ' + (props.isEdit ? 'col-md-6' : 'col-md-12')}>
                                <label htmlFor='roles' className='mb-2'>Role</label>
                                <select
                                    name='roles'
                                    className={'form-control form-select ' + (!props.formState.roles && 'no-select-value')}
                                    value={props.formState.roles}
                                    onChange={props.onChangeHandler}
                                    required
                                >
                                    <option value="">Select Role</option>
                                    {roles.map((role, index) => {
                                        return (
                                            <option value={role.value} key={index}>{role.name}</option>
                                        )
                                    })}
                                </select>
                            </div>

                            {props.isEdit &&
                                <div className='mt-5 col-md-6 d-flex align-items-center justify-content-between'>
                                    <label className='mb-2'>Disable user? </label>
                                    <Switch
                                        onChange={props.onChangeHandlerSwitch}
                                        checked={props.formState.isDisabled}
                                        checkedIcon={<span
                                            style={{color: '#fff', marginLeft: 5, lineHeight: '27px'}}>On</span>}
                                        uncheckedIcon={false}
                                        onHandleColor="#fff"
                                        offHandleColor='#B3B3C5'
                                        onColor='#79BEF1'
                                        offColor='#F4FAFF'
                                        handleDiameter={20}
                                        boxShadow="0px rgba(0, 0, 0, 0)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0)"
                                        checkedIcon={<div
                                            className='d-flex pt-1 text-white align-items-center justify-content-center'>Yes</div>}
                                        uncheckedIcon={<div
                                            className='d-flex pt-1 align-items-center justify-content-center'
                                            style={{color: '#B3B3C5'}}>No</div>}
                                    />
                                </div>
                            }
                        </div>


                        {/*<div className='mt-4'>*/}
                        {/*    <div style={{fontSize: 14}}>Access Permission</div>*/}
                        {/*    <div className='d-block d-md-flex justify-content-md-between m-0 mt-3'>*/}
                        {/*        <div className="form-check text-start d-flex align-items-center">*/}
                        {/*            <input*/}
                        {/*                type="checkbox"*/}
                        {/*                className="form-check-input"*/}
                        {/*                id="add_product"*/}
                        {/*                name='add_product'*/}
                        {/*                checked={props.formState.add_product}*/}
                        {/*                onChange={props.onChangeHandler}*/}
                        {/*            />*/}
                        {/*            <label className="form-check-label ms-2" htmlFor="add_product">Add Product</label>*/}
                        {/*        </div>*/}

                        {/*        <div className="form-check text-start d-flex align-items-center">*/}
                        {/*            <input*/}
                        {/*                type="checkbox"*/}
                        {/*                className="form-check-input"*/}
                        {/*                id="add_catalog"*/}
                        {/*                name='add_catalog'*/}
                        {/*                checked={props.formState.add_catalog}*/}
                        {/*                onChange={props.onChangeHandler}*/}
                        {/*            />*/}
                        {/*            <label className="form-check-label ms-2" htmlFor="add_catalog">Add Catalog</label>*/}
                        {/*        </div>*/}

                        {/*        <div className="form-check text-start d-flex align-items-center">*/}
                        {/*            <input*/}
                        {/*                type="checkbox"*/}
                        {/*                className="form-check-input"*/}
                        {/*                id="download_report"*/}
                        {/*                name='download_report'*/}
                        {/*                checked={props.formState.download_report}*/}
                        {/*                onChange={props.onChangeHandler}*/}
                        {/*            />*/}
                        {/*            <label className="form-check-label ms-2" htmlFor="download_report">Download*/}
                        {/*                Report</label>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}

                        {/*    <div>*/}
                        {/*        <div className="form-check text-start d-flex align-items-center">*/}
                        {/*            <input*/}
                        {/*                type="checkbox"*/}
                        {/*                className="form-check-input"*/}
                        {/*                id="is_demo"*/}
                        {/*                name='is_demo'*/}
                        {/*                checked={props.formState.is_demo}*/}
                        {/*                onChange={props.onChangeHandler}*/}
                        {/*            />*/}
                        {/*            <label className="form-check-label ms-2" htmlFor="is_demo">Is Demo Account</label>*/}
                        {/*        </div>*/}

                        {/*    </div>*/}
                        {/*</div>*/}

                        <button type={"submit"} className="button-custom btn mt-4">Submit</button>
                    </form>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default UserModal;
