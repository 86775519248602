import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import Layout from "../layout";
import PieChart from "../components/dashboard/PieChart";

const Dashboard = () => {

    const dispatch = useDispatch();
    const attributes = useSelector((el) => el.attributeData.attributeList)

    // useEffect(() => {
    //     if (localStorage.getItem('token')) {
    //         dispatch(getAllAttributeData());
    //     }
    // }, [])

    return (
        <Layout topic={"Dashboard"}>
            <div className='container mt-2'>
                <div className='row'>
                    <div className='col-md-4'>
                        <PieChart />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Dashboard;