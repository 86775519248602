import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Form} from "react-bootstrap";

import Layout from "../layout";
import ImgUpload from "../components/ImgUpload";
import OverlaySpinner from "../components/OverlaySpinner";

import capitalizeFirstLetter from "../helpers/capitalizeHelper";
import {getAlcoholAttributes, getFashionAttributes, getImageAttributes} from "../redux/actions/imageExtraActions";

const AlcoholFurnitureFashion=({type})=>{

    const dispatch = useDispatch();
    const imageDivRef = useRef(null)

    const gotError = useSelector((el) => el.imageExtraData.gotError);
    const isLoading = useSelector((el) => el.imageExtraData.isLoading);
    const attributeList = useSelector((el) => el.imageExtraData.attributeList);
    const alcoholAttributeList = useSelector((el) => el.imageExtraData.alcoholAttributeList);
    const fashionAttributeList = useSelector((el) => el.imageExtraData.fashionAttributeList);

    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const [fileState, setFileState] = useState([]);
    const [processedImage, setProcessedImage] = useState(null);
    const [processed, setProcessed] = useState([]);
    const [fileType, setFileType] = useState(type === 'alcohol' ? 'image' : 'url')
    const [inputUrl, setInputUrl] = useState('')

    useEffect(()=>{
        setProcessed([])
    }, [])

    useEffect(() => {
        if (attributeList) getData('furniture');
    }, [JSON.stringify(attributeList)])

    useEffect(() => {
        if(alcoholAttributeList) getData('alcohol');
    }, [JSON.stringify(alcoholAttributeList)])

    useEffect(() => {
        if(fashionAttributeList) getData('fashion');
    }, [JSON.stringify(fashionAttributeList)])

    const onChangeHandler = (value) => {
        setFileType(value);
        setInputUrl('');
        setFileState([])
    }

    const onSubmitClick = async () => {
        await setProcessedImage(null)
        await setProcessed([])
        setIsSubmitClicked(true);

        if(type === 'alcohol'){
            dispatch(getAlcoholAttributes(fileState[0], inputUrl));
        } else if(type === 'fashion'){
            dispatch(getFashionAttributes(fileState[0], inputUrl));
        } else if(type === 'furniture'){
            dispatch(getImageAttributes(fileState[0], inputUrl));
        }

        setTimeout(() => {
            imageDivRef.current?.scrollIntoView();
        }, 500)
    }

    const getMaxConfidenceElement = (arr, dataType) => {
        let maxxConfidence = -1, maxxConfidenceVal = '';
        for (let i = 0; i < arr.length; ++i) {
            let curConfidence;
            let curVal;

            if(dataType === 'fashion'){
                curConfidence = parseFloat(arr[i][1]);
                curVal = arr[i][0];
            } else {
                curConfidence = parseFloat(arr[i].confidence);
                curVal = arr[i].prediction;
            }

            if (curConfidence > maxxConfidence) {
                maxxConfidenceVal = curVal;
                maxxConfidence = curConfidence;
            }
        }

        return maxxConfidenceVal;
    }

    const getData = async (pageType) => {
        let data;
        let processedData = [];
        if(pageType === 'alcohol'){
            data = alcoholAttributeList[Object.keys(alcoholAttributeList)[0]];

            let parent_keys = Object.keys(data);
            for (let i = 0; i < parent_keys.length; ++i) {
                processedData.push({ key: parent_keys[i], value: data[parent_keys[i]]})
            }
        } else if(pageType === 'fashion'){
            data = fashionAttributeList[Object.keys(fashionAttributeList)[0]];

            let parent_keys = Object.keys(data);
            for (let i = 0; i < parent_keys.length; ++i) {
                let maxxValue = getMaxConfidenceElement(data[parent_keys[i]], 'fashion');
                processedData.push({ key: parent_keys[i].substr(0, parent_keys[i].length - 5), value: maxxValue})
            }
        } else if(pageType === 'furniture') {
            data = attributeList[Object.keys(attributeList)[0]];

            let parent_keys = Object.keys(data);
            for (let i = 0; i < parent_keys.length; ++i) {
                let parent_key = parent_keys[i];
                if (Array.isArray(data[parent_key]) && data[parent_key].length > 0) {
                    let maxxValue = getMaxConfidenceElement(data[parent_key]);

                    processedData.push({ key: parent_key, value: maxxValue })
                }
                else if (typeof data[parent_key] === 'object') {
                    let child_keys = Object.keys(data[parent_key]);
                    for (let j = 0; j < child_keys.length; ++j) {
                        let child_key = child_keys[j];
                        if (Array.isArray(data[parent_key][child_key]) && data[parent_key][child_key].length > 0) {
                            let maxxValue = getMaxConfidenceElement(data[parent_key][child_key]);
                            processedData.push({ 'key': `${parent_key}'s ${child_key}`, value: maxxValue });
                        }
                    }
                }
            }
        }
        await setProcessed(processedData)
        await setProcessedImage(inputUrl ? inputUrl : fileState[0])
    }

    return (
        <Layout topic={type[0].toUpperCase() + type.substr(1)}>
            <div className="img-enhancement-container">
                <div className='round-container p-4'>
                    <div className='d-flex mb-4 mt-2'>

                        {type === 'alcohol' ?
                            <Form.Check
                                type='radio'
                                value='image'
                                name='file-type'
                                label={'Image'}
                                onChange={() => onChangeHandler("image")}
                                checked={fileType === 'image'}
                                className='me-5'
                            />
                        :''}

                        <Form.Check
                            type='radio'
                            value='url'
                            name='file-type'
                            label={'URL'}
                            onChange={() => onChangeHandler("url")}
                            checked={fileType === 'url'}
                        />

                    </div>

                    {fileType === 'image' ?
                        <ImgUpload
                            isSingle={true}
                            fileState={fileState}
                            setFileState={setFileState}
                        />
                        :
                        <div className='mt-4 mt-md-5 d-flex flex-column flex-md-row align-items-start align-items-md-center'>
                            <label htmlFor='url' className='mb-2 normal-font text-nowrap ms-0 ms-md-2 me-5'>Enter URL</label>
                            <input
                                type='text'
                                name='url'
                                className='form-control'
                                placeholder='Enter URL...'
                                value={inputUrl}
                                onChange={(e) => setInputUrl(e.target.value)}
                                style={{ maxWidth: '700px' }}
                            />
                        </div>
                    }

                    <div className='mt-3 submit-btn text-end'>
                        <button
                            onClick={onSubmitClick}
                            disabled={(fileType === 'image' && !fileState.length) || (fileType === 'url' && !inputUrl)}
                            className="button-custom btn"
                        >
                            Submit
                        </button>
                    </div>
                </div>

                {isSubmitClicked &&
                    <div className='round-container mt-4 p-4 mb-5' ref={imageDivRef}>
                        <div className='sub-headers'>Processed Data</div>

                        <div className='position-relative processed-img-container'>
                            <OverlaySpinner isNormal={true} isLoading={isLoading} loadingText={"Processing. Please wait..."} />
                            {((type === 'furniture' && attributeList) || (type === 'alcohol' && alcoholAttributeList) || (type === 'fashion' && fashionAttributeList)) &&
                                <div className='text-center'>
                                    {gotError ?
                                        <div className='text-danger pt-3 normal-font'>There was an error while processing the image</div>
                                        :
                                        <div>
                                            <img src={typeof processedImage === 'string' ? processedImage : processedImage ? URL.createObjectURL(processedImage) : null} style={{ maxWidth: "400px", maxHeight: "400px" }} className="mb-4" />
                                            <div className='attribute-mapping-container mt-4 mt-md-5'>
                                                <div className='attribute-mapping-headers d-flex justify-content-evenly mb-4'>
                                                    <div>Attribute</div>
                                                    <div>Value</div>
                                                </div>

                                                {processed.map((el, index) => {
                                                    return (
                                                        <div key={type+ "-" +index} className='attribute-mapping-row d-flex align-items-center'>
                                                            <div>{capitalizeFirstLetter(el.key)}</div>
                                                            <div>{el.value}</div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                }

            </div>
        </Layout>
    )
}

export default AlcoholFurnitureFashion;