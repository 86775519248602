import React from "react";
import capitalizeFirstLetter from "../helpers/capitalizeHelper";


const HeaderMappingTable=(props)=>{

    const onChangeHandler=(e, item)=>{
        const {value} = e.target;

        props.setMapState({
            ...props.mapState,
            [item]: value
        })
        props.setIsMapped(value !== '');
    }

    return(
        <div className='attribute-mapping-container mt-4 mt-md-5'>
            <div className='attribute-mapping-headers d-flex justify-content-evenly mb-4'>
                <div>ICL Attribute</div>
                <div>Attribute</div>
            </div>

            {props.headers.map((el, index)=>{
                return(
                    <div className='attribute-mapping-row d-flex align-items-center'>
                        <div>{index + 1}</div>
                        <div>{capitalizeFirstLetter(el)}</div>
                        <div>
                            <select
                                name={'mapping-' +el}
                                className={'form-control form-select ' + (!props.mapState[el] && 'no-select-value')}
                                value={props.mapState[el]}
                                onChange={(e)=> onChangeHandler(e, el)}
                                required
                            >
                                <option value="">{props.placeholder}</option>
                                {props.fileHeaders.map((el, index) => {
                                    return (
                                        <option value={el} key={index}>{capitalizeFirstLetter(el)}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default HeaderMappingTable;