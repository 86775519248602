import produce from "immer";

import * as jobActionTypes from '../actionTypes/jobActionTypes'
import * as clientActionTypes from '../actionTypes/clientActionTypes'
import * as brandActionTypes from '../actionTypes/brandActionTypes'
import * as attributeActionTypes from '../actionTypes/attributeActionTypes'
import * as imageExtraActionTypes from '../actionTypes/imageExtraActionTypes'

const initialState = {
    isLoading: false,
    isTableLoading: false,
    error: null,
    isGetJobDataDone: false,
};


const loadingData = produce((state = initialState, action) => {
    const {type, payload} = action;

    if(type === jobActionTypes.GET_JOB_PRODUCT_DATA_REQUEST){
        state.isTableLoading = true;
        state.error = null;
        return state;
    } else if(type === clientActionTypes.GET_CLIENT_USER_DATA_REQUEST){
        state.isTableLoading = true;
        state.error = null;
        return state;
    } else if(type === jobActionTypes.GET_ALL_JOB_DATA_REQUEST){
        if(payload) {
            state.isLoading = true;
        }
        state.error = null;
        return state;
    } else if(type === imageExtraActionTypes.IMAGE_ENHANCEMENT_REQUEST || type === imageExtraActionTypes.GET_IMAGE_ATTRIBUTES_REQUEST || type === imageExtraActionTypes.GET_ALCOHOL_ATTRIBUTES_REQUEST || type === imageExtraActionTypes.GET_FASHION_ATTRIBUTES_REQUEST || type === imageExtraActionTypes.LOGO_DETECTION_REQUEST){
        state.error = null;
        return state;
    } else if(type.includes('REQUEST')){
        state.isLoading = true;
        state.error = null;
        return state;
    } else if(type === jobActionTypes.GET_JOB_PRODUCT_DATA_SUCCEED){
        state.isTableLoading = false;
        state.error = null;
        return state;
    } else if(type === clientActionTypes.GET_CLIENT_USER_DATA_SUCCEED){
        state.isTableLoading = false;
        state.error = null;
        return state;
    } else if(type === jobActionTypes.GET_ALL_JOB_DATA_SUCCEED){
        if(!state.isGetJobDataDone) {
            state.isGetJobDataDone = true;
        }
        state.isLoading = false;
        state.error = null;
        return state;
    } else if(type === jobActionTypes.SUBMIT_JOB_FILE_SUCCEED || type === brandActionTypes.UPLOAD_BRAND_DATA_SUCCEED){
        state.error = null;
        return state;
    } else if(type === attributeActionTypes.ATTRIBUTE_UPDATE_SUCCEED){
        return state;
    } else if(type.includes('SUCCEED')) {
        state.isLoading = false;
        state.error = null;
        return state;
    } else if(type.includes('FAILED')){
        state.isTableLoading = false;
        state.isLoading = false;
        state.error = payload;
        return state;
    } else {
        return state;
    }
})


export default loadingData;