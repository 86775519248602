import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import Layout from "../layout";
import NewJob from "../components/job/NewJob";
import JobDetailDiv from "../components/job/JobDetailDiv";
import VerificationTable from "../components/attribute-verification/VerificationTable";

import {
    createJobViaImage, downloadJobData,
    getAllHeaderMappingData, getAllJobDetails,
    submitJobCSV
} from "../redux/actions/jobActions";
import {
    getAttributesFromFile,
    getJobDetailsForVerification,
    getProductsDetailsForVerification, updateAttributes
} from "../redux/actions/attributeActions";
import {Alert} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import JobTable from "../components/job/JobTable";
import {JWTReader} from "../helpers/JWTReader";
import JobDetails from "../components/job/JobDetails";

const showingData = [
    "GTIN",
    "Product Name",
    'title',
    'product_id',
    'brand',
    'sub_brand',
    'variant',
    'feature_benefits',
    'product_dimensions',
    'manufacturer',
    'manufacturer_phone',
    'manufacturer_address',
    'ingredients',
    'allergens',
    'servings',
    'labels',
    'prep_usage',
    'nutrition_label',
    'net_weight',
    'net_weight_uom',
    'serving_per_container',
    'product_type',
    'category',
    'calories_per_serving',
    'alcohol_type',
    'alc_vol',
    'botteled_by',
    'product_of',
    'year',
    'warning'
]

const AttributeVerification = () => {

    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const jobFileHeaders = useSelector((el) => el.jobData.jobFileHeaders);
    const mappingHeaders = useSelector((el) => el.jobData.mappingHeaders);
    const jobDetails = useSelector((el) => el.attributeData.jobDetails);
    const parentAttributeList = useSelector((el) => el.attributeData.parentAttributeList)
    const childAttributeList = useSelector((el) => el.attributeData.childAttributeList)
    const allJobDetails = useSelector((el) => el.jobData.allJobData)

    const [formState, setFormState] = useState({
        gtin: '',
        url: ''
    })
    const [fileState, setFileState] = useState([])
    const [fileType, setFileType] = useState('csv')
    const [alert, setAlert] = useState('')
    const [isMapping, setIsMapping] = useState(false)
    const [mapState, setMapState] = useState({})
    const [isMapped, setIsMapped] = useState(false)
    const [processStep, setProcessStep] = useState(1);
    const [currentIndex, setCurrentIndex] = useState();

    useEffect(() => {
        if (params.jobid) {
            dispatch(getJobDetailsForVerification(params.jobid));
            if (params.pid) {
                dispatch(getProductsDetailsForVerification(params.pid))
                setProcessStep(3)
            } else {
                setProcessStep(2);
            }
        } else {
            dispatch(getAllJobDetails(true, true))
            setProcessStep(1)
        }
    }, [JSON.stringify(params)])

    useEffect(() => {
        if (localStorage.getItem('token')) {
            dispatch(getAllHeaderMappingData(true));
        }
    }, [])

    useEffect(() => {
        if (!currentIndex && params.pid && jobDetails) {
            setCurrentIndex(jobDetails.products.findIndex((el) => el.pid === params.pid))
        }
    }, [JSON.stringify(jobDetails?.products)])

    useEffect(() => {
        if (jobFileHeaders && jobFileHeaders.length && fileState.length) {
            setIsMapping(true)
        }
    }, [JSON.stringify(jobFileHeaders)])

    useInterval(() => {
        if (jobDetails && jobDetails.status === "processing") {
            dispatch(getJobDetailsForVerification(jobDetails.jobid, true));
        }
    }, 10000);

    function useInterval(callback, delay) {
        const savedCallback = useRef();

        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        useEffect(() => {
            function tick() {
                savedCallback.current();
            }

            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    }

    const onSubmitClick = () => {
        if (fileType === 'csv') {
            dispatch(getAttributesFromFile(fileState[0], mapState))
                .then((res) => {
                    if (res) {
                        setIsMapping(false)
                        setFileState([])
                        setMapState({})
                        setProcessStep(2)
                        navigate(`/attribute-verification/${res}`);
                    }
                })
        } else if (fileType === 'image') {
            dispatch(createJobViaImage(fileState, formState.gtin))
                .then((res) => {
                    if (res) {
                        setFileState([])
                        setFormState({
                            gtin: '',
                            url: ''
                        })
                        setFileType('csv')
                        setProcessStep(2)
                    }
                })
        }
    }

    const onMappingHeaders = () => {
        if (fileType === 'csv') {
            dispatch(submitJobCSV(fileState[0]))
        }
    }

    const onBackClick = () => {
        navigate(`/attribute-verification/${params.jobid}`);
    }

    const onNextClick = () => {
        if (currentIndex !== jobDetails.products.length - 1) {
            const pid = jobDetails.products[currentIndex + 1].pid;
            onClickProduct(pid, currentIndex + 1);
        }
    }

    const onPrevClick = () => {
        if (currentIndex !== 0) {
            const pid = jobDetails.products[currentIndex - 1].pid;
            onClickProduct(pid, currentIndex - 1);
        }
    }

    const onClickProduct = (pid, index) => {
        setCurrentIndex(index)
        navigate(`/attribute-verification/${params.jobid}/${pid}`);
    }

    const onUpdateAttributes = (e) => {
        let data = {
            ...e,
            pid: params.pid
        }

        dispatch(updateAttributes(data))
            .then((res) => {
                if (res) {
                    setAlert("Attributes updated successfully")
                    navigate(`/attribute-verification/${params.jobid}`);
                    setTimeout(() => {
                        setAlert('')
                    }, 8000)
                }
            })
    }

    const onDownloadJob = (id, type) => {
        dispatch(downloadJobData(id, type, true))
    }

    return (
        <Layout topic={"Attribute Verification"}>
            {alert &&
                <Alert
                    style={{
                        position: 'fixed',
                        height: 70,
                        width: '100%',
                        top: 0,
                        left: 0,
                        zIndex: 1010,
                        textAlign: 'right',
                        borderRadius: 0
                    }}
                    variant={'success'}
                    className='normal-font d-flex align-items-center justify-content-center'
                >
                    {alert}
                </Alert>
            }

            <div className='container mt-2 attributes-verification-container'>

                {processStep === 1 && !isMapping &&
                    <div className='mb-5'>
                        <JobDetails
                            jobDetails={allJobDetails}
                        />
                    </div>
                }

                {processStep === 1 ?
                    <>
                        <NewJob
                            onMappingHeaders={onMappingHeaders}
                            onSubmitClick={onSubmitClick}
                            isMapping={isMapping}
                            jobFileHeaders={jobFileHeaders}
                            mappingHeaders={mappingHeaders}
                            mapState={mapState}
                            setMapState={setMapState}
                            isMapped={isMapped}
                            setIsMapped={setIsMapped}
                            fileType={fileType}
                            fileState={fileState}
                            setFileState={setFileState}
                            formState={formState}
                            setFormState={setFormState}
                        />

                        {!isMapping &&
                            <>
                                <div className='mt-4'>
                                    <div className='sub-headers'>Jobs</div>
                                    <div className='mt-2'>
                                        <JobTable
                                            client_code={JWTReader(localStorage.getItem('token'))?.client_code}
                                            data={allJobDetails.data}
                                            onDownloadJob={onDownloadJob}
                                            isVerification={true}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    </>
                    : processStep === 2 ?
                        <>
                            {jobDetails &&
                                <JobDetailDiv
                                    onClickProduct={onClickProduct}
                                    jobState={jobDetails}
                                    isVerification={true}
                                />
                            }
                        </>

                        : (childAttributeList && (parentAttributeList || mappingHeaders)) ?

                            <VerificationTable
                                itemPlace={currentIndex === 0 ? 'first' : currentIndex === (jobDetails?.products.length - 1) ? 'last' : null}
                                onPrevClick={onPrevClick}
                                onNextClick={onNextClick}
                                onBackClick={onBackClick}
                                onUpdateAttributes={onUpdateAttributes}
                                data={
                                    parentAttributeList ?
                                        parentAttributeList.map((el) => {
                                            el = el.toLowerCase();
                                            if (showingData.some((el2) => el2.toLowerCase() === el)) {
                                                return {
                                                    attribute: el,
                                                    currentValue: childAttributeList["current_" + el],
                                                    value: childAttributeList[el],
                                                }
                                            }
                                        }).filter((el) => !!el) :

                                        mappingHeaders ?
                                            mappingHeaders.map((el) => {
                                                el = el.toLowerCase();
                                                if (showingData.some((el2) => el2.toLowerCase() === el)) {
                                                    return {
                                                        attribute: el,
                                                        currentValue: childAttributeList["current_" + el],
                                                        value: childAttributeList[el],
                                                    }
                                                }
                                            }).filter((el) => !!el) : []
                                }
                            />
                            : ''
                }
            </div>
        </Layout>
    )
}

export default AttributeVerification