import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import Layout from "../layout";
import CatalogTable from "../components/catalog/CatalogTable";

import {ReactComponent as Plus} from "../assets/Plus.svg";

import {downloadCatalogProducts, getAllCatalogDataDetails} from "../redux/actions/catalogActions";


const CatalogList = () => {

    const dispatch = useDispatch();
    const catalogData = useSelector((el) => el.catalogData.allCatalogData)

    useEffect(() => {
        dispatch(getAllCatalogDataDetails());
    }, [])

    const onDownloadProducts = (ids) => {
        dispatch(downloadCatalogProducts(ids))
    }

    return (
        <Layout topic={"Catalog"}>
            <div className='container'>
                <div className='mt-2 round-container pt-4 pb-1 mb-2 row mx-auto'>
                    <div className='mb-3 d-flex justify-content-between align-items-center'>
                        <div>Consumer Goods</div>
                    </div>

                    <div>
                        <CatalogTable
                            catalogData={catalogData}
                            onDownloadProducts={onDownloadProducts}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default CatalogList;