import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Breadcrumb, Tab, Tabs } from "react-bootstrap";

import Layout from "../layout";
import CatalogImage from "../components/catalog/CatalogImage";

import { getSingleCatalogDataDetails, updateProductAttribute } from "../redux/actions/catalogActions";

import capitalizeFirstLetter from "../helpers/capitalizeHelper";
import request from "../helpers/requestHelper";

import { ReactComponent as Close } from '../assets/Close.svg'
import { ReactComponent as Edit } from '../assets/Edit.svg'
import { ReactComponent as Tick } from '../assets/tick.svg'

const names = [
    { name: "GTIN/UPC", value: "gtin" },
    { name: "Title", value: "title" },
    { name: "Category", value: "category" },
    { name: "Net Weight", value: "net_weight" },
    { name: "Brand", value: "brand" },
    { name: "Sub-brand", value: "sub_brand" },
    { name: "Product Category", value: "product_category" },
    { name: "Product Classification", value: "classification" },
    { name: "Variant", value: "variant" },
    { name: "Net Weight UOM", value: "net_weight_uom" }
]

const names2 = [
    { name: "Ingredients", value: "ingredients" },
    { name: "Warnings", value: "warnings" },
    { name: "Allergens", value: "allergens" },
    { name: "Prep/Usage", value: "prep_usage" },
    // { name: "Serving Per Container", value: "servings per container" },
    // { name: "Serving Size", value: "serving_size" },
    { name: "Calories Per Serving", value: "calories_per_serving" },
]

const names3 = [
    { name: "Manufacturer", value: "manufacturer" },
    { name: "Manufacturer Phone", value: "manufacturer_phone" },
    { name: "Distributor Address", value: "distributor_address" },
    { name: "Country of Origin", value: "country_of_origin" },
    { name: "Manufacturer Address", value: "manufacturer_address" },
    { name: "Distributor", value: "distributor" },
    { name: "Distributor Phone", value: "distributor_phone" },
    { name: "Feature", value: "features" }
]

const ProductDetail = ({isForeign}) => {

    const dispatch = useDispatch();
    const params = useParams();

    const imgRef = useRef(null);
    const detailDivRef = useRef(null);

    const catalogData = useSelector((el) => el.catalogData.singleCatalogData)

    const [editVar, setEditVar] = useState(null)
    const [changedValues, setChangedValues] = useState({})
    const [savedValues, setSavedValues] = useState({})
    const [changedNutrients, setChangedNutrients] = useState({})
    const [savedNutrients, setSavedNutrients] = useState({})
    const [nutrientName, setNutrientName] = useState('')
    const [selectedTab, setSelectedTab] = useState('core')
    const [selectedImg, setSelectedImg] = useState({
        path: '',
        id: ''
    })
    const [imgState, setImgState] = useState({})

    useEffect(() => {
        dispatch(getSingleCatalogDataDetails(params.productId, isForeign));
        setImgState({})
    }, [])

    useEffect(() => {
        if (catalogData && catalogData.text_extracted) {
            onDownloadImages();
        }
    }, [JSON.stringify(catalogData)])


    const onDownloadImages = async () => {
        let data = catalogData.text_extracted;

        if (data && data.length) {
            let obj = {};

            for (let i = 0; i < data.length; i++) {
                let path = Object.keys(data[i])[0];
                obj[path] = null;

                const res = await request({
                    url: `/${isForeign ? "int_products" : "products"}/get_image?path=${path}`,
                    auth: true,
                    method: 'GET',
                    responseType: 'blob'
                });
                obj[path] = URL.createObjectURL(res.data)
                await setImgState({ ...imgState, ...obj })
            }
            setImgState({ ...imgState, ...obj })
        }
    }

    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        setChangedValues({
            ...changedValues,
            [name]: value
        })
    }

    const onChangeHandlerNutrients = (e) => {
        const { name, value } = e.target;

        setChangedNutrients({
            ...changedNutrients,
            [name]: value
        })
    }

    const onSaveValue = (val, isNutri) => {
        if (isNutri) {
            let value = val === 'untitled' ? nutrientName : val
            setSavedNutrients({
                ...savedNutrients,
                [value]: changedNutrients[val]
            })
            if (val === 'untitled') {
                let values = changedNutrients;
                values[value] = changedNutrients[val]

                delete values[val];
                setChangedNutrients(values)
                setNutrientName('')
            }
        } else {
            setSavedValues({
                ...savedValues,
                [val]: changedValues[val]
            })
        }
        setEditVar(null)
    }

    const onDeleteValue = (val, isNutri) => {
        if (isNutri) {
            let values = changedNutrients;
            delete values[val];

            setChangedNutrients(values)
            if (val === 'untitled') {
                setNutrientName('')
            }
        } else {
            let values = changedValues;
            delete values[val];

            setChangedValues(values)
        }
        setEditVar(null)
    }

    const updateAttributes = () => {
        let data = {
            ...savedValues,
            pid: params.productId,
        };

        if (savedNutrients) {
            data.nutrients = {
                ...catalogData.nutrients,
                ...savedNutrients
            }
        }

        dispatch(updateProductAttribute(data, isForeign))
        setChangedNutrients({})
        setChangedValues({})
        setSavedNutrients({})
        setChangedNutrients({})
        setEditVar(null)
    }

    const AddNew = () => {
        setChangedNutrients({
            ...changedNutrients,
            "untitled": ""
        })
        setEditVar("untitled")
    }

    return (
        <Layout topic={"Product Details"}>

            <Breadcrumb>
                {params.jobId ?
                    <>
                        <Breadcrumb.Item className='normal-font breadcrumb-custom'><Link className='color-secondary'
                            to={'/jobs'}>Jobs</Link></Breadcrumb.Item>
                        <Breadcrumb.Item className='normal-font breadcrumb-custom'><Link className='color-secondary'
                            to={`/jobs/${params.jobId}`}>{params.jobId}</Link></Breadcrumb.Item>
                    </>
                    :
                    <Breadcrumb.Item className='normal-font breadcrumb-custom'><Link className='color-secondary'
                        to={'/catalogs'}>Catalog</Link></Breadcrumb.Item>
                }

                <Breadcrumb.Item className='normal-font' active>{params.productId}</Breadcrumb.Item>
            </Breadcrumb>

            <div className='round-container pt-4 pb-4 mb-2 row mx-auto product-detail-container'>
                {(catalogData.text_extracted && catalogData.text_extracted.length) &&
                    <div className='row p-1 p-md-5 mt-4 main-container'>
                        <div className='col-md-6 text-center'>
                            <div className='d-flex align-items-center'>
                                <img
                                    ref={imgRef}
                                    src={(catalogData.text_extracted && catalogData.text_extracted.length) ? selectedImg.path ? imgState[selectedImg.path] : imgState[Object.keys(catalogData.text_extracted[0])[0]] : null}
                                    alt='main-product-page' />
                            </div>
                        </div>
                        <div className='col-md-6 mx-auto normal-font mt-4 mt-md-0'>
                            <div className='d-flex align-items-center'>
                                {(catalogData.text_extracted && catalogData.text_extracted.length && ((!selectedImg.id && catalogData.text_extracted[0][Object.keys(catalogData.text_extracted[0])[0]]) || (selectedImg.id && catalogData.text_extracted[selectedImg.id][Object.keys(catalogData.text_extracted[selectedImg.id])[0]]))) &&
                                    <div className='detail-wrapper mx-auto p-4'>
                                        {(catalogData.text_extracted && catalogData.text_extracted.length) ? selectedImg.id ? catalogData.text_extracted[selectedImg.id][Object.keys(catalogData.text_extracted[selectedImg.id])[0]] : catalogData.text_extracted[0][Object.keys(catalogData.text_extracted[0])[0]] : null}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }

                {catalogData.text_extracted && catalogData.text_extracted.length &&
                    <div className='d-flex mx-auto images-container pb-4 pt-5 pt-md-0'>
                        {catalogData.text_extracted.map((el, index) => {
                            return (
                                <CatalogImage
                                    setSelectedImg={setSelectedImg}
                                    imgState={imgState}
                                    catalogData={catalogData}
                                    el={el}
                                    index={index}
                                    imgRef={imgRef}
                                    detailDivRef={detailDivRef}
                                />
                            )
                        })}
                    </div>
                }

                <div
                    className={'p-1 p-md-5 ' + (!(catalogData.text_extracted && catalogData.text_extracted.length) ? 'mt-4 pt-0' : 'mt-5')}>
                    <Tabs
                        defaultActiveKey={selectedTab}
                        transition={true}
                        className="mb-3 normal-font"
                        onSelect={(e) => setSelectedTab(e)}
                    >
                        <Tab eventKey="core" title="Core" tabClassName={selectedTab === 'core' && 'selected-tab'}>
                            <div className='row normal-font'>
                                {names.map((el) => {
                                    return (
                                        <div className='col-md-6 row pt-2 pb-2 position-relative detail-row'>
                                            <div className='col-6 color-secondary'>{el.name} :</div>
                                            {editVar === el.value ?
                                                <div className='col-6'>
                                                    <input
                                                        name={el.value}
                                                        type="text"
                                                        defaultValue={catalogData[el.value]}
                                                        value={changedValues[el.value]}
                                                        onChange={onChangeHandler}
                                                        className='form-control'
                                                    />
                                                </div>
                                                :
                                                <div className='col-6'>{savedValues.hasOwnProperty(el.value) ? savedValues[el.value] : catalogData[el.value]}</div>
                                            }

                                            <div className='icon-holder d-flex'>
                                                <div className='d-flex'>
                                                    {editVar === el.value ?
                                                        <>
                                                            <div className='icon-container-3 cursor-pointer' onClick={() => onSaveValue(el.value)}><Tick height={20} /></div>
                                                            <div className='icon-container-4 cursor-pointer' onClick={() => onDeleteValue(el.value)}><Close height={9} /></div>
                                                        </>
                                                        :
                                                        <div className={'cursor-pointer icon-container-2 ' + (!editVar ? 'edit-icon' : 'visually-hidden')} onClick={() => setEditVar(el.value)}><Edit height={9} /></div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </Tab>

                        <Tab eventKey="dietary" title="Dietary" tabClassName={selectedTab === 'dietary' && 'selected-tab'}>
                            <div className='row normal-font'>
                                {names2.slice(0, 2).map((el) => {
                                    return (
                                        <div className='row pt-2 pb-2 detail-row position-relative'>
                                            <div className='color-secondary col-4 col-md-3'>{el.name}:</div>

                                            {editVar === el.value ?
                                                <div className='col-8 col-md-9'>
                                                    <input
                                                        name={el.value}
                                                        type="text"
                                                        defaultValue={catalogData[el.value]}
                                                        value={changedValues[el.value]}
                                                        onChange={onChangeHandler}
                                                        className='form-control'
                                                    />
                                                </div>
                                                :
                                                <div className='col-8 col-md-9'>{savedValues.hasOwnProperty(el.value) ? savedValues[el.value] : catalogData[el.value]}</div>
                                            }

                                            <div className='icon-holder d-flex'>
                                                <div className='d-flex'>
                                                    {editVar === el.value ?
                                                        <>
                                                            <div className='icon-container-3 cursor-pointer' onClick={() => onSaveValue(el.value)}><Tick height={20} /></div>
                                                            <div className='icon-container-4 cursor-pointer' onClick={() => setEditVar(null)}><Close height={9} /></div>
                                                        </>
                                                        :
                                                        <div className={'cursor-pointer icon-container-2 ' + (!editVar ? 'edit-icon' : 'visually-hidden')} onClick={() => setEditVar(el.value)}><Edit height={9} /></div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                                {names2.slice(2).map((el) => {
                                    return (
                                        <div className='col-md-6 row pt-2 pb-2 position-relative detail-row'>
                                            <div className='col-6 color-secondary'>{el.name} :</div>
                                            {editVar === el.value ?
                                                <div className='col-6'>
                                                    <input
                                                        name={el.value}
                                                        type="text"
                                                        defaultValue={catalogData[el.value]}
                                                        value={changedValues[el.value]}
                                                        onChange={onChangeHandler}
                                                        className='form-control'
                                                    />
                                                </div>
                                                :
                                                <div className='col-6'>{savedValues.hasOwnProperty(el.value) ? savedValues[el.value] : catalogData[el.value]}</div>
                                            }

                                            <div className='icon-holder d-flex'>
                                                <div className='d-flex'>
                                                    {editVar === el.value ?
                                                        <>
                                                            <div className='icon-container-3 cursor-pointer' onClick={() => onSaveValue(el.value)}><Tick height={20} /></div>
                                                            <div className='icon-container-4 cursor-pointer' onClick={() => onDeleteValue(el.value)}><Close height={9} /></div>
                                                        </>
                                                        :
                                                        <div className={'cursor-pointer icon-container-2 ' + (!editVar ? 'edit-icon' : 'visually-hidden')} onClick={() => setEditVar(el.value)}><Edit height={9} /></div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </Tab>

                        <Tab eventKey="nutritionLabel" title="Nutrition Facts"
                            tabClassName={selectedTab === 'nutritionLabel' && 'selected-tab'}
                        >
                            {catalogData.nutrients &&
                                <div className='row normal-font'>
                                    {Object.keys({ ...catalogData.nutrients, ...changedNutrients }).map((el) => {
                                        return (
                                            <div className='col-md-6 row pt-2 pb-2 detail-row position-relative'>
                                                {el === "untitled" ?
                                                    <div className='col-6'>
                                                        <input
                                                            name={el}
                                                            type="text"
                                                            value={nutrientName}
                                                            onChange={(e) => setNutrientName(e.target.value)}
                                                            className='form-control'
                                                        />
                                                    </div>
                                                    :
                                                    <div className='col-6 color-secondary'>{capitalizeFirstLetter(el)} :</div>
                                                }
                                                {editVar === el ?
                                                    <div className='col-6'>
                                                        <input
                                                            name={el}
                                                            type="text"
                                                            defaultValue={catalogData.nutrients[el]}
                                                            value={changedNutrients[el]}
                                                            onChange={onChangeHandlerNutrients}
                                                            className='form-control'
                                                        />
                                                    </div>
                                                    :
                                                    <div className='col-6'>{savedNutrients.hasOwnProperty(el) ? savedNutrients[el] : catalogData.nutrients[el]}</div>
                                                }
                                                <div className='icon-holder d-flex'>
                                                    <div className='d-flex'>
                                                        {editVar === el ?
                                                            <>
                                                                <div className='icon-container-3 cursor-pointer' onClick={() => onSaveValue(el, true)}><Tick height={20} /></div>
                                                                <div className='icon-container-4 cursor-pointer' onClick={() => onDeleteValue(el, true)}><Close height={9} /></div>
                                                            </>
                                                            :
                                                            <div className={'cursor-pointer icon-container-2 ' + (!editVar ? 'edit-icon' : 'visually-hidden')} onClick={() => setEditVar(el)}><Edit height={9} /></div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                        </Tab>

                        <Tab eventKey="other" title="Manufacturer Info" tabClassName={selectedTab === 'other' && 'selected-tab'}>
                            <div className='row normal-font'>
                                {names3.map((el) => {
                                    return (
                                        <div className='col-md-6 row pt-2 pb-2 position-relative detail-row'>
                                            <div className='col-6 color-secondary'>{el.name} :</div>
                                            {editVar === el.value ?
                                                <div className='col-6'>
                                                    <input
                                                        name={el.value}
                                                        type="text"
                                                        defaultValue={catalogData[el.value]}
                                                        value={changedValues[el.value]}
                                                        onChange={onChangeHandler}
                                                        className='form-control'
                                                    />
                                                </div>
                                                :
                                                <div className='col-6'>{savedValues.hasOwnProperty(el.value) ? savedValues[el.value] : catalogData[el.value]}</div>
                                            }

                                            <div className='icon-holder d-flex'>
                                                <div className='d-flex'>
                                                    {editVar === el.value ?
                                                        <>
                                                            <div className='icon-container-3 cursor-pointer' onClick={() => onSaveValue(el.value)}><Tick height={20} /></div>
                                                            <div className='icon-container-4 cursor-pointer' onClick={() => onDeleteValue(el.value)}><Close height={9} /></div>
                                                        </>
                                                        :
                                                        <div className={'cursor-pointer icon-container-2 ' + (!editVar ? 'edit-icon' : 'visually-hidden')} onClick={() => setEditVar(el.value)}><Edit height={9} /></div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </Tab>


                    </Tabs>

                    <div className='ms-auto d-flex' style={{ width: 'max-content', whiteSpace: 'nowrap' }}>
                        {selectedTab === 'nutritionLabel' && <button onClick={AddNew} className="button-custom btn mt-4 ps-4 pe-4 pt-2 pb-2 me-2">Add New Nutrient</button>}
                        <button onClick={updateAttributes} className="button-custom btn mt-4 ps-4 pe-4 pt-2 pb-2">Save</button>
                    </div>

                </div>
            </div>
        </Layout>
    )
}

export default ProductDetail;