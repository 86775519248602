import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate, useParams} from "react-router-dom";
import moment from "moment";
import {Breadcrumb} from "react-bootstrap";

import Layout from "../layout";
import JobDataTable from "../components/job/JobDataTable";

import capitalizeFirstLetter from "../helpers/capitalizeHelper";

import {downloadJobData, getJobProductDetails, getSingleJobDetails} from "../redux/actions/jobActions";

const JobResult = ({isForeign}) => {

    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const singleJobDetails = useSelector((el) => el.jobData.singleJobData)
    const jobDetails = useSelector((el) => el.jobData.allJobData)
    const jobProductData = useSelector((el) => el.jobData.jobProductData)
    const errorData = useSelector((el) => el.loadingData.error)
    const isLoading = useSelector((el) => el.loadingData.isTableLoading)

    const [jobState, setJobState] = useState({
        id: "",
        date: "",
        noOfRecords: null,
        status: "",
    })

    useEffect(() => {
        if (jobDetails.data && jobDetails.data.some((el) => el.jobid === params.jobId)) {
            let job = jobDetails.data.find((el) => el.jobid === params.jobId)
            setJobState({
                id: job.jobid,
                date: job.submit_date,
                noOfRecords: job.product_count,
                status: job.status,
            })
        }
        dispatch(getSingleJobDetails(params.jobId, false, isForeign));
    }, [])

    useEffect(()=>{
        if(singleJobDetails){
            setJobState({
                id: singleJobDetails.jobid,
                date: singleJobDetails.submit_date,
                noOfRecords: singleJobDetails.product_count,
                status: singleJobDetails.status,
            })
        }
    }, [JSON.stringify(singleJobDetails)])

    useEffect(() => {
        if (errorData && (errorData.code === 404 || errorData?.message?.includes("Sorry"))) {
            navigate(`/`)
        }
    }, [errorData])

    const getJobProductData = (id) => {
        dispatch(getJobProductDetails(id, false, isForeign))
    }

    const onDownloadFiles = async (id, type) => {
        dispatch(downloadJobData(id, type, false, isForeign))
    }

    return (
        <Layout topic={"Job Details"}>
            <div className='mb-4'>
                <div className='container'>

                    <Breadcrumb>
                        <Breadcrumb.Item className='normal-font breadcrumb-custom'><Link className='color-secondary' to={'/jobs'}>Jobs</Link></Breadcrumb.Item>
                        <Breadcrumb.Item className='normal-font' active>{jobState.id}</Breadcrumb.Item>
                    </Breadcrumb>

                    <div
                        className='round-container pt-4 pb-4 ps-5 pe-5 m-0 mt-4 d-block d-md-flex justify-content-between job-detail-container'>
                        <div>
                            <div>Job ID:</div>
                            <div>{jobState.id}</div>
                        </div>

                        <div>
                            <div>Date Submitted:</div>
                            <div>{moment(jobState.date).format("DD/MM/YYYY")}</div>
                        </div>

                        <div>
                            <div>No of Records:</div>
                            <div>{jobState.noOfRecords}</div>
                        </div>

                        <div>
                            <div>Status:</div>
                            <div>{capitalizeFirstLetter(jobState.status)}</div>
                        </div>

                        {(jobState.status === "completed") &&
                            <div className='download-column'>
                                <div className='d-flex justify-content-center'>
                                    <div onClick={() => onDownloadFiles(jobState.id, 'csv')}
                                         className='color-secondary me-3'>CSV
                                    </div>
                                    <div onClick={() => onDownloadFiles(jobState.id, 'json')}
                                         className='color-secondary me-3'>JSON
                                    </div>
                                    <div onClick={() => onDownloadFiles(jobState.id, 'xls')}
                                         className='color-secondary'>XLS
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    <div className='mt-4'>
                        <JobDataTable
                            jobid={jobState.id}
                            data={singleJobDetails && singleJobDetails.products ? singleJobDetails.products : []}
                            isForeign={isForeign}
                        />
                    </div>

                </div>
            </div>
        </Layout>
    )
}

export default JobResult;