import React from "react";

import AlcoholFurnitureFashion from "../views/Alcohol-Furniture-Fashion";

const Fashion = () => {

    return (
        <AlcoholFurnitureFashion type="fashion" />
    )
}

export default Fashion;