import {useDispatch, useSelector} from "react-redux";
import {Alert} from "react-bootstrap";
import {useEffect, useState} from "react";

import OverlaySpinner from "./components/OverlaySpinner";

// import {getAllJobDetails} from "./redux/actions/jobActions";

import AppRoutes from "./Routes";

function App() {

    const dispatch = useDispatch();

    const isLoading = useSelector((el)=> el.loadingData.isLoading)
    const errorData = useSelector((el) => el.loadingData.error)

    const [alert, setAlert] = useState('')

    useEffect(() => {
        if (errorData && (errorData.code === 404 || errorData.status === "KO" || errorData.message) ) {
            setAlert(errorData?.message)
            setTimeout(()=>{
                setAlert('')
            }, 5000)
        }
    }, [errorData])

    return (
        <div>
            <OverlaySpinner isLoading={isLoading}/>
            {alert && <Alert style={{position:'fixed', height:70, width:'100%', top:0, left:0, zIndex:1010, borderRadius:0}} variant={'danger'} className='normal-font d-flex align-items-center justify-content-center'>{alert}</Alert> }
            <AppRoutes/>
        </div>
    );
}

export default App;
