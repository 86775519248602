import React from "react";

import AlcoholFurnitureFashion from "../views/Alcohol-Furniture-Fashion";

const Alcohol = () => {

    return (
        <AlcoholFurnitureFashion type="alcohol" />
    )
}

export default Alcohol;