import React from "react";

import AlcoholFurnitureFashion from "../views/Alcohol-Furniture-Fashion";


const ImageFurniture = () => {

    return(
        <AlcoholFurnitureFashion type="furniture" />
    )
}

export default ImageFurniture;