import React from "react";
import {Dropdown} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {userLogout} from "../redux/actions/authActions";
import {Link, useNavigate} from "react-router-dom";

import {ReactComponent as MenuIcon} from "../assets/sidebar/Menu.svg";
import capitalizeHelper from "../helpers/capitalizeHelper";


const Header=(props)=>{

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onLogoutClick=()=>{
        dispatch(userLogout());
        navigate('/login')
    }

    return(
        <div className='header-container'>
            <div className='container container-new d-flex justify-content-between align-items-center'>
                <div className='header-topic align-items-center d-flex'>
                    <div onClick={props.handleToggle} style={{background:"#79BEF11A", padding:"7px 11px", borderRadius:"12px", cursor:'pointer'}}><MenuIcon /></div>
                    <div className='ms-2 ms-md-4'>{props.header}</div>
                </div>
                <div className='text-end d-flex align-items-center me-0 me-md-4'>
                    <div className="avatar-lg rounded-circle mb-0 mt-1 mt-md-2" style={{maxHeight:'100%', background:"#FFF"}}>
                        <img
                            src={`/images/avatar.png`}
                            alt=""
                            className="rounded-circle border profile-img"
                            style={{maxWidth:'100%', maxHeight:'50px'}}
                        />
                    </div>
                    <div className='ms-2 mt-2' style={{fontSize:14}}>{props.username ? capitalizeHelper(props?.username) : ''}</div>
                    {/*<Dropdown className='dropdown-user-options'>*/}
                    {/*    <Dropdown.Toggle id="dropdown-basic">*/}
                    {/*        <div className="avatar-lg rounded-circle mb-0 mt-2" style={{maxHeight:'100%', background:"#FFF"}}>*/}
                    {/*            <img*/}
                    {/*                src={`/images/avatar.png`}*/}
                    {/*                alt=""*/}
                    {/*                className="rounded-circle profile-img"*/}
                    {/*                style={{maxWidth:'100%', maxHeight:'50px'}}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </Dropdown.Toggle>*/}

                    {/*    <Dropdown.Menu align={'end'}>*/}
                    {/*        <Dropdown.Item className='normal-font text-center' >Change Password</Dropdown.Item>*/}
                    {/*        <Dropdown.Item className='normal-font text-center' onClick={onLogoutClick}>Logout</Dropdown.Item>*/}
                    {/*    </Dropdown.Menu>*/}
                    {/*</Dropdown>*/}
                </div>
            </div>
        </div>
    )
}

export default Header;