import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {PaginationProvider} from 'react-bootstrap-table2-paginator';
import React, {useState} from "react";
import capitalizeFirstLetter from "../../helpers/capitalizeHelper";
import SortIcon from "../../assets/sort.png";
import {useNavigate} from "react-router-dom";

const SIZE_PER_PAGE = 10;

const columns = [{
    dataField: 'gtin',
    text: 'GTIN',
    sort: true,
    style: {
        cursor: 'pointer',
        textDecoration: 'underline'
    },
    sortCaret: () => {
        return (
            <span>
                    <img src={SortIcon} style={{width:16, marginLeft:5, height:12, fill:"gray"}}/>
                </span>
        );
    }
}, {
    dataField: 'status',
    text: 'Status',
    sort: true,
    sortCaret: () => {
        return (
            <span>
                    <img src={SortIcon} style={{width:16, marginLeft:5, height:12, fill:"gray"}}/>
                </span>
        );
    },
    formatter: (rowContent, row) => {
        return capitalizeFirstLetter(row.status)
    }
}, {
    dataField: 'number_of_input_images',
    text: 'No of Images',
    sort: true,
    sortCaret: () => {
        return (
            <span>
                    <img src={SortIcon} style={{width:16, marginLeft:5, height:12, fill:"gray"}}/>
                </span>
        );
    }
}, {
    dataField: 'number_of_error_images',
    text: 'No of Error Images',
    sort: true,
    sortCaret: () => {
        return (
            <span>
                    <img src={SortIcon} style={{width:16, marginLeft:5, height:12, fill:"gray"}}/>
                </span>
        );
    }
}];

const JobDataTable = (props) => {

    const options = {
        custom: true,
        totalSize: props.data.length,
        sizePerPage: SIZE_PER_PAGE
    };

    const navigate = useNavigate();

    const handleNextPage = ({page, onPageChange}) => () => {
        onPageChange(page + 1);
    }

    const handlePrevPage = ({page, onPageChange}) => () => {
        onPageChange(page - 1);
    }

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            if(props.isVerification){
                props.onClickProduct(row.pid, rowIndex)
            } else {
                navigate(`/${props.isForeign ? "int_jobs" : "jobs"}/${props.jobid}/product/${row.pid}`);
            }
        },
    };


    return (

        <div style={{marginBottom: 100, position: 'relative'}}>
            <PaginationProvider
                pagination={paginationFactory(options)}
            >
                {
                    ({
                         paginationProps,
                         paginationTableProps
                     }) => {
                        return (
                            <div style={{
                                background: "#FFF",
                                borderRadius: "16px",
                                padding: "0 20px",
                                overflowX: "auto"
                            }}>
                                <BootstrapTable
                                    keyField="pid"
                                    data={props.data}
                                    columns={columns}
                                    rowEvents={rowEvents}
                                    classes="job-table-class"
                                    headerClasses="job-table-header-class"
                                    bodyClasses="job-table-body-class"
                                    {...paginationTableProps}
                                />
                                <div className='text-end pb-3' style={{
                                    marginTop: "-17px",
                                    position: 'absolute',
                                    right: 0,
                                    background: "#FFF",
                                    borderRadius: "0 0 16px 16px",
                                    width: "100%"
                                }}>
                                    <div className='pe-4'>
                                        {paginationProps.page !== 1 &&
                                            <button className="btn normal-font color-secondary"
                                                    onClick={handlePrevPage(paginationProps)}>Prev</button>}
                                        {((paginationProps.totalSize + (SIZE_PER_PAGE - (paginationProps.totalSize % SIZE_PER_PAGE))) / SIZE_PER_PAGE) !== paginationProps.page &&
                                            <button className="btn normal-font color-secondary"
                                                    onClick={handleNextPage(paginationProps)}>Next</button>}

                                    </div>
                                </div>
                            </div>
                        )
                    }
                }
            </PaginationProvider>
        </div>
    )
}

export default JobDataTable;