import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {saveAs} from 'file-saver';
import {Alert} from "react-bootstrap";

import Layout from "../layout";
import AttributeMappingTable from "../components/AttributeMappingTable";
import Brand_AttributeUploadModal from "../components/Brand_AttributeUploadModal";

import {ReactComponent as Upload} from '../assets/Upload.svg'
import {ReactComponent as Download} from '../assets/Download.svg'

import {getAllAttributeData, uploadAttributesDataset} from "../redux/actions/attributeActions";
import request from "../helpers/requestHelper";

const AttributeList = () => {

    const dispatch = useDispatch();
    const attributes = useSelector((el) => el.attributeData.attributeList)
    const userAttributes = useSelector((el) => el.attributeData.userAttributeList)
    // const brandDataCount = useSelector((el) => el.brandData.brand_count)

    const [mapState, setMapState] = useState({})
    const [isMapped, setIsMapped] = useState(false)
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [alert, setAlert] = useState('')

    useEffect(() => {
        if (localStorage.getItem('token')) {
            dispatch(getAllAttributeData());
        }
    }, [])

    const onSubmitClick = () => {

    }

    const onUploadAttributes = (file) => {
        setIsUploadModalOpen(false);
        dispatch(uploadAttributesDataset(file))
            .then((res) => {
                if (res) {
                    setAlert(res)
                    setTimeout(() => {
                        setAlert('')
                    }, 8000)
                }
            })
    }

    const onDownloadSampleAttributes = async () => {

        const res = await request({
            url: `/attribute_mapping/download`,
            auth: true,
            method: 'GET',
            responseType: 'blob'
        });
        const blob = new Blob([res.data])
        let name = `attributes.csv`

        saveAs(blob, name)
    }

    return (
        <Layout topic={"Attribute Mapping"}>
            <div className='container mt-2'>
                <div className='mt-0 round-container row m-0 p-5 pe-3 ps-3 p-md-5 attributes-container'>
                    {alert &&
                        <Alert
                            style={{
                                position: 'fixed',
                                height: 70,
                                width: '100%',
                                top: 0,
                                left: 0,
                                zIndex: 1010,
                                textAlign: 'right',
                                borderRadius: 0
                            }}
                            variant={'success'}
                            className='normal-font d-flex align-items-center justify-content-center'>
                            {alert}
                        </Alert>
                    }
                    <div style={{width: 550}}>
                        <div className='d-flex justify-content-between'>
                            <div className='sub-headers'>Product Extraction Mapping</div>
                        </div>
                        <div className='mt-4'>
                            <div className='d-flex justify-content-between pt-2 pb-2 flex-column flex-md-row'>
                                <div className='d-flex'>
                                    <div className='d-flex normal-font align-items-center text-nowrap me-4'>
                                        <div onClick={() => setIsUploadModalOpen(true)}
                                             className='icons-container me-3 cursor-pointer'><Upload/></div>
                                        <div style={{cursor: 'default'}}>Upload Attributes</div>
                                    </div>
                                    <div className='d-flex normal-font align-items-center'>
                                        <div onClick={onDownloadSampleAttributes}
                                             className='icons-container me-2 cursor-pointer'><Download/></div>
                                        <div style={{cursor: 'default'}}>Download</div>
                                    </div>
                                </div>
                                <div className='mt-3 mt-md-0'>
                                    <button
                                        onClick={onSubmitClick}
                                        className="button-custom btn ps-4 pe-4 pt-2 pb-2"
                                        // disabled={Object.keys(attributes).length !== Object.keys(mapState).length || !isMapped}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                        <AttributeMappingTable
                            placeholder={'Client Attribute'}
                            headers={attributes}
                            userAttributes={userAttributes}
                            fileHeaders={[]}
                            mapState={mapState}
                            setMapState={setMapState}
                            isMapped={isMapped}
                            setIsMapped={setIsMapped}
                        />
                    </div>

                    <Brand_AttributeUploadModal
                        isBrand={false}
                        modalShow={isUploadModalOpen}
                        toggleModal={() => setIsUploadModalOpen(false)}
                        onUploadDataset={onUploadAttributes}
                    />
                </div>
            </div>
        </Layout>
    )
}

export default AttributeList;