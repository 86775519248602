import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { JWTReader } from "./helpers/JWTReader";

import Login from "./pages/Login";
import JobsList from "./pages/JobsList";
import JobResult from "./pages/JobResult";
import ClientsList from "./pages/ClientsList";
import BrandsList from "./pages/BrandsList";
import CatalogList from "./pages/CatalogList";
import AttributeList from "./pages/AttributeList";
import ProductDetail from "./pages/ProductDetail";
import Dashboard from "./pages/Dashboard";
import AttributeVerification from "./pages/AttributeVerification";
import ImageEnhancement from "./pages/ImageEnhancement";
import LogoDetector from "./pages/LogoDetector";
import ImageFurniture from "./pages/ImageFurniture";
import GtinFinder from "./pages/GtinFinder";
import Fashion from "./pages/Fashion";
import Alcohol from "./pages/Alcohol";

const AppRoutes = () => {

    if (localStorage.getItem('token')) {
        return (
            <BrowserRouter>
                <Routes>
                    <Route exact path='/' element={<Dashboard />} />
                    <Route exact path='/image-enhancement' element={<ImageEnhancement />} />
                    <Route exact path='/logo-detector' element={<LogoDetector />} />
                    <Route exact path='/furniture' element={<ImageFurniture />} />
                    <Route exact path='/fashion' element={<Fashion />} />
                    <Route exact path='/alcohol' element={<Alcohol />} />
                    <Route exact path='/jobs' element={<JobsList isForeign={false} />} />
                    <Route exact path='/int_jobs' element={<JobsList isForeign={true} />} />
                    <Route exact path='/gtin-finder' element={<GtinFinder />} />
                    <Route exact path='/jobs/:jobId' element={<JobResult isForeign={false} />} />
                    <Route exact path='/int_jobs/:jobId' element={<JobResult isForeign={true} />} />
                    <Route exact path='/jobs/:jobId/product/:productId' element={<ProductDetail isForeign={false} />} />
                    <Route exact path='/int_jobs/:jobId/product/:productId' element={<ProductDetail isForeign={true} />} />
                    {JWTReader(localStorage.getItem('token'))?.client_code?.toLowerCase() === 'icl' && <Route exact path='/brands' element={<BrandsList />} />}
                    <Route exact path='/catalogs' element={<CatalogList />} />
                    <Route exact path='/catalogs/:productId' element={<ProductDetail />} />
                    <Route exact path='/attributes' element={<AttributeList />} />
                    <Route exact path='/attribute-verification' element={<AttributeVerification />} />
                    <Route exact path='/attribute-verification/:jobid' element={<AttributeVerification />} />
                    <Route exact path='/attribute-verification/:jobid/:pid' element={<AttributeVerification />} />
                    {JWTReader(localStorage.getItem('token')).roles !== 'USER' && <Route exact path='/companies' element={<ClientsList />} />}
                    <Route path="*" element={<Navigate replace to="/logo-detector" />} />
                </Routes>
            </BrowserRouter>
        )
    } else {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path='/login' element={<Login />} />
                    <Route path="*" element={<Navigate replace to="/login" />} />
                </Routes>
            </BrowserRouter>
        )
    }

}

export default AppRoutes;