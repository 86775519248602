import { useEffect, useRef, useState } from "react";
import { FileDrop } from "react-file-drop";

import { ReactComponent as Gallery } from "../assets/Gallery.svg";
import { ReactComponent as Delete } from "../assets/Delete.svg";


const ImgUpload = (props) => {

    const fileInputRef = useRef(null);

    const onFileInputChange = (event) => {
        onFileDrop(event.target.files)
    }

    const onTargetClick = (e) => {
        fileInputRef.current.click()
    }

    const onFileDrop = (files) => {
        let arr = [];

        if (!props.isSingle) {
            for (let i = 0; i < props.fileState.length; i++) {
                arr.push(props.fileState[i])
            }
        }

        for (let i = 0; i < files.length; i++) {
            let exe = files[i].name.split('.').pop().toLowerCase();
            if (exe === 'png' || exe === 'jpg' || exe === 'jpeg') {
                arr.push(files[i]);
                if (props.isSingle) break;
            }
        }
        props.setFileState(arr);
    }

    const onDeleteClick = (index) => {
        let arr = [];
        for (let i = 0; i < props.fileState.length; i++) {
            if (i !== index) arr.push(props.fileState[i])
        }
        props.setFileState(arr);
    }

    return (
        <div className='mt-0 form-list'>
            <div>
                <input
                    type='file'
                    name='file'
                    id='file'
                    accept={'.png, .jpg, .jpeg'}
                    ref={fileInputRef}
                    onChange={onFileInputChange}
                    style={{ display: 'none' }}
                    multiple={!props.isSingle}
                />
                <div>
                    <FileDrop
                        onTargetClick={onTargetClick}
                        onDrop={onFileDrop}
                    >
                        <div className='p-5 pb-3' style={{ maxWidth: '100%', width: '100%' }}>
                            <Gallery height={50} />
                            <div className='mt-4'>Click or drag images here to upload</div>
                            {/*<div style={{color: '#B3B3C5'}} className='mt-2'>*/}
                            {/*    (Front image, Back image, Side images (optional), Nutrition image (optional)*/}
                            {/*</div>*/}

                            <div className='mt-4 pt-2 pb-0 d-flex mx-auto img-container-upload'>
                                {props.fileState.map((el, index) => {
                                    return (
                                        <div onClick={(e) => {
                                            e.stopPropagation()
                                        }} style={{ position: 'relative', cursor: 'default' }}
                                            className={'img-placeholder d-flex align-items-center justify-content-center no-click ' + (props.fileState.length === 1 ? 'mx-auto' : index === 0 ? 'ms-auto' : index === (props.fileState.length - 1) ? 'me-auto' : '')}>
                                            <img onClick={(e) => {
                                                e.stopPropagation()
                                            }} style={{ maxWidth: 90, maxHeight: 90, cursor: 'default' }}
                                                src={URL.createObjectURL(el)} className='no-click' />
                                            <div onClick={(e) => {
                                                e.stopPropagation();
                                                onDeleteClick(index)
                                            }} className='delete-icon cursor-pointer'><Delete /></div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </FileDrop>
                </div>
            </div>
        </div>
    )
}

export default ImgUpload;