import axios from "axios";

const baseURL_https = process.env.REACT_APP_API_ENDPOINT_HTTPS;
const baseURL_http = process.env.REACT_APP_API_ENDPOINT_HTTP;

export const request = async (config) => {
    let defaultHeaders = {
        // "Access-Control-Allow-Origin": "*",
    }

    const { auth, data, url, method, responseType, headers } = config || {};

    defaultHeaders = {
        ...defaultHeaders,
        ...headers
    }

    if (auth) {
        const token = await localStorage.getItem('token');

        defaultHeaders = {
            ...defaultHeaders,
            ...headers,
            'Authorization': `Bearer ${token}`
        }

    }

    return new Promise((resolve, reject) => {
        axios({
            method: method,
            url: `${baseURL_https}${url}`,
            headers: defaultHeaders,
            data: data,
            responseType: responseType ? responseType : null
        })
            .then((data) => {
                if (responseType === 'blob') {
                    resolve(data)
                } else {
                    resolve(data.data);
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 401 && !url.includes('login')) {
                    localStorage.removeItem('token');
                }
                reject(error.response)
            })
    })

}

export const request_http = async (config) => {
    let defaultHeaders = {
        "Access-Control-Allow-Origin": "*",
    }

    const { auth, data, url, method, responseType, headers } = config || {};

    defaultHeaders = {
        ...defaultHeaders,
        ...headers
    }

    if (auth) {
        const token = await localStorage.getItem('token');

        defaultHeaders = {
            ...defaultHeaders,
            ...headers,
            'Authorization': `Bearer ${token}`
        }

    }

    return new Promise((resolve, reject) => {
        axios({
            method: method,
            url: `${baseURL_http}${url}`,
            headers: defaultHeaders,
            data: data,
            responseType: responseType ? responseType : null
        })
            .then((data) => {
                if (responseType === 'blob') {
                    resolve(data)
                } else {
                    resolve(data.data);
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 401 && !url.includes('login')) {
                    localStorage.removeItem('token');
                }
                reject(error.response)
            })
    })

}


export default request;