import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Form } from "react-bootstrap";

import Layout from "../layout";
import JobTable from "../components/job/JobTable";
import FileUpload from "../components/FileUpload";


import {
    createJob,
    createJobViaImage, downloadJobData,
    getAllHeaderMappingData,
    getAllJobDetails,
    submitJobCSV
} from "../redux/actions/jobActions";
import HeaderMappingTable from "../components/HeaderMappingTable";
import { JWTReader } from "../helpers/JWTReader";
import JobDetails from "../components/job/JobDetails";
import {useLocation} from "react-router-dom";

const JobsList = ({isForeign}) => {

    const location = useLocation();
    const dispatch = useDispatch();
    const jobDetails = useSelector((el) => el.jobData.allJobData)
    const jobFileHeaders = useSelector((el) => el.jobData.jobFileHeaders)
    const mappingHeaders = useSelector((el) => el.jobData.mappingHeaders)

    const [formState, setFormState] = useState({
        gtin: '',
        url: ''
    })
    const [fileState, setFileState] = useState([])
    const [fileType, setFileType] = useState('csv')
    const [alert, setAlert] = useState('')
    const [isMapping, setIsMapping] = useState(false)
    const [mapState, setMapState] = useState({})
    const [isMapped, setIsMapped] = useState(false)

    useEffect(() => {
        if (localStorage.getItem('token')) {
            dispatch(getAllJobDetails(true, false, isForeign));
            dispatch(getAllHeaderMappingData());
        }
    }, [location.pathname])

    useEffect(() => {
        if (jobFileHeaders && jobFileHeaders.length && fileState.length) {
            setIsMapping(true)
        }
    }, [JSON.stringify(jobFileHeaders)])

    useEffect(() => {
        const interval = setInterval(() => {
            if (localStorage.getItem('token'))
                dispatch(getAllJobDetails(false, false, isForeign));
        }, 10000);
        return () => clearInterval(interval);
    }, [location.pathname])


    const onSubmitClick = () => {
        if (fileType === 'csv') {
            dispatch(createJob(fileState[0], mapState, isForeign))
                .then((res) => {
                    if (res) {
                        setAlert(res)
                        setIsMapping(false)
                        setFileState([])
                        setMapState({})
                        setTimeout(() => {
                            setAlert('')
                        }, 8000)
                    }
                })
        } else if (fileType === 'image') {
            dispatch(createJobViaImage(fileState, formState.gtin, isForeign))
                .then((res) => {
                    if (res) {
                        setAlert(res)
                        setFileState([])
                        setFormState({
                            gtin: '',
                            url: ''
                        })
                        setFileType('csv')
                        setTimeout(() => {
                            setAlert('')
                        }, 8000)
                    }
                })
        }
    }

    const onMappingHeaders = () => {
        if (fileType === 'csv') {
            dispatch(submitJobCSV(fileState[0]))
        }
    }

    const onChangeHandler = (e) => {
        const { value } = e.target;
        setFileType(value)
        setFileState([])
    }

    const onDownloadJob = (id, type) => {
        dispatch(downloadJobData(id, type, false, isForeign))
    }

    return (
        <Layout topic={"New Job"}>
            <div>
                {alert && <Alert style={{
                    position: 'fixed',
                    height: 70,
                    width: '100%',
                    top: 0,
                    left: 0,
                    zIndex: 1010,
                    textAlign: 'right',
                    borderRadius: 0
                }} variant={'success'}
                    className='normal-font d-flex align-items-center justify-content-center'>{alert}</Alert>}
                <div className='container'>
                    <JobDetails
                        jobDetails={jobDetails}
                    />

                    {isMapping ?
                        <div className='mt-4 round-container pt-4 pb-4 row m-0 mt-2'>
                            <div style={{ maxWidth: 450 }}>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className='sub-headers'>Product Extraction Mapping</div>
                                    <div>
                                        <button
                                            onClick={onSubmitClick}
                                            className="button-custom btn ps-4 pe-4 pt-2 pb-2"
                                        >
                                            Extract
                                        </button>
                                    </div>
                                </div>
                                <HeaderMappingTable
                                    placeholder={'ICL Attribute'}
                                    headers={mappingHeaders}
                                    fileHeaders={jobFileHeaders}
                                    mapState={mapState}
                                    setMapState={setMapState}
                                    isMapped={isMapped}
                                    setIsMapped={setIsMapped}
                                />
                            </div>
                        </div>
                        :
                        <div className='mt-4'>
                            <div className='sub-headers'>New Job</div>
                            <div className='round-container pt-4 pb-4 row m-0 mt-2'>

                                <div className={'d-flex ' + (fileType === 'csv' ? 'mb-5' : 'mb-2')}>
                                    <Form.Check
                                        type='radio'
                                        value='csv'
                                        name='file-type'
                                        label={'.CSV/.XLS'}
                                        onChange={onChangeHandler}
                                        checked={fileType === 'csv'}
                                        className='me-5'
                                    />

                                    {/*<Form.Check*/}
                                    {/*    type='radio'*/}
                                    {/*    value='url'*/}
                                    {/*    name='file-type'*/}
                                    {/*    label={'URL'}*/}
                                    {/*    onChange={onChangeHandler}*/}
                                    {/*    checked={fileType === 'url'}*/}
                                    {/*    className='me-5'*/}
                                    {/*/>*/}

                                    <Form.Check
                                        type='radio'
                                        value='image'
                                        name='file-type'
                                        label={'Image'}
                                        onChange={onChangeHandler}
                                        checked={fileType === 'image'}
                                    />

                                </div>

                                <div
                                    className={'d-flex ' + (fileType === 'csv' ? 'align-items-center flex-column flex-md-row' : fileType === 'url' ? 'align-items-end flex-column flex-md-row' : 'flex-column')}>
                                    <div>
                                        <FileUpload
                                            fileState={fileState}
                                            setFileState={setFileState}
                                            fileType={fileType}
                                            formState={formState}
                                            setFormState={setFormState}
                                        />
                                    </div>
                                    <div className={fileType === 'image' ? 'ms-0 mt-3' : 'ms-0 mt-2 mt-md-0 ms-md-5'}>
                                        <div style={{ width: 150 }}>
                                            <button onClick={() => fileType === 'csv' ? onMappingHeaders() : onSubmitClick()}
                                                disabled={fileType === 'csv' ? !fileState.length : fileType === 'url' ? !formState.gtin : (!formState.gtin || !fileState.length)}
                                                className="button-custom btn">Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className='mt-4'>
                        <div className='sub-headers'>Jobs</div>
                        <div className='mt-2'>
                            <JobTable
                                client_code={JWTReader(localStorage.getItem('token'))?.client_code}
                                data={jobDetails.data}
                                onDownloadJob={onDownloadJob}
                                isForeign={isForeign}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default JobsList;