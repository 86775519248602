import React from "react";

import HeaderMappingTable from "../HeaderMappingTable";
import FileUpload from "../FileUpload";

const NewJob = (
    {
        isMapping,
        onSubmitClick,
        onMappingHeaders,
        mappingHeaders,
        jobFileHeaders,
        mapState,
        setMapState,
        isMapped,
        setIsMapped,
        fileType,
        fileState,
        setFileState,
        formState,
        setFormState
    }
) => {
    return (
        <div>
            {isMapping ?
                <div className='mt-4 round-container pt-4 pb-4 row m-0 mt-2'>
                    <div style={{ maxWidth: 450 }}>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='sub-headers'>Product Extraction Mapping</div>
                            <div>
                                <button
                                    onClick={onSubmitClick}
                                    className="button-custom btn ps-4 pe-4 pt-2 pb-2">
                                    Verify
                                </button>
                            </div>
                        </div>
                        <HeaderMappingTable
                            placeholder={'ICL Attribute'}
                            headers={mappingHeaders}
                            fileHeaders={jobFileHeaders}
                            mapState={mapState}
                            setMapState={setMapState}
                            isMapped={isMapped}
                            setIsMapped={setIsMapped}
                        />
                    </div>
                </div>
                :
                <div className='round-container p-4'>
                    <div
                        className={'d-flex ' + (fileType === 'csv' ? 'align-items-center flex-column flex-md-row' : fileType === 'url' ? 'align-items-end flex-column flex-md-row' : 'flex-column')}>
                        <div>
                            <FileUpload
                                fileState={fileState}
                                setFileState={setFileState}
                                fileType={fileType}
                                formState={formState}
                                setFormState={setFormState}
                            />
                        </div>
                        <div className={fileType === 'image' ? 'ms-0 mt-3' : 'ms-0 mt-2 mt-md-0 ms-md-5'}>
                            <div style={{ width: 150 }}>
                                <button onClick={() => fileType === 'csv' ? onMappingHeaders() : onSubmitClick()}
                                    disabled={fileType === 'csv' ? !fileState.length : fileType === 'url' ? !formState.gtin : (!formState.gtin || !fileState.length)}
                                    className="button-custom btn">Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default NewJob;