import { saveAs } from 'file-saver';

import * as jobActionTypes from '../actionTypes/gtinActionTypes'
import * as catalogActionTypes from "../actionTypes/catalogActionTypes";

import { request } from '../../helpers/requestHelper';

export const getAllJobDetails = (isInitial) => async dispatch => {
    dispatch({ type: jobActionTypes.GET_ALL_JOB_DATA_REQUEST, payload: isInitial })

    try {
        const res = await request({
            url: `/gtin_model/getjobs`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: jobActionTypes.GET_ALL_JOB_DATA_SUCCEED,
            payload: res
        });


    } catch (err) {
        dispatch({
            type: jobActionTypes.GET_ALL_JOB_DATA_FAILED,
            payload: err?.data ? err.data : err
        });
    }
}

export const createJob = (file) => async dispatch => {
    dispatch({ type: jobActionTypes.SUBMIT_JOB_FILE_REQUEST });

    let data = new FormData();
    data.append('file', file);

    try {
        const res = await request({
            url: `/gtin_model/`,
            auth: true,
            method: 'POST',
            data: data
        });

        dispatch({
            type: jobActionTypes.SUBMIT_JOB_FILE_SUCCEED,
            payload: res
        });

        dispatch(getAllJobDetails())
        return res?.data?.message;

    } catch (err) {
        dispatch({
            type: jobActionTypes.SUBMIT_JOB_FILE_FAILED,
            payload: err?.data ? err.data : err
        });

        return false;
    }
}

export const downloadJobData = (id, type) => async dispatch => {

    try {
        const res = await request({
            url: `/gtin_model/?jobid=${id}&file_type=${type}`,
            auth: true,
            method: 'GET',
            responseType: 'blob'
        });

        let name = `${id}.${type}`
        const blob = new Blob([res.data], {
            type: `application/${type}`,
        });
        saveAs(blob, name)

    } catch (err) {
        dispatch({
            type: catalogActionTypes.GET_SINGLE_CATALOG_DATA_FAILED,
            payload: err?.data ? err.data : err
        });
    }
}