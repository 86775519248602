import * as imageExtraActionTypes from '../actionTypes/imageExtraActionTypes'

import produce from "immer";

const initialState = {
    isLoading: false,
    gotError: false,
    image: null,
    bboxCoords: null,
    attributeList: null,
    alcoholAttributeList: null,
    fashionAttributeList: null
};

const imageExtraData = produce(
    (state = initialState, action) => {
        const { type, payload } = action;

        switch (type) {

            // Request
            case imageExtraActionTypes.IMAGE_ENHANCEMENT_REQUEST: {
                state.isLoading = true;
                state.gotError = false;
                state.image = null;
                return state;
            }
            case imageExtraActionTypes.GET_IMAGE_ATTRIBUTES_REQUEST: {
                state.isLoading = true;
                state.gotError = false;
                state.attributeList = null;
                return state;
            }
            case imageExtraActionTypes.GET_ALCOHOL_ATTRIBUTES_REQUEST: {
                state.isLoading = true;
                state.gotError = false;
                state.alcoholAttributeList = null;
                return state;
            }
            case imageExtraActionTypes.GET_FASHION_ATTRIBUTES_REQUEST: {
                state.isLoading = true;
                state.gotError = false;
                state.fashionAttributeList = null;
                return state;
            }
            case imageExtraActionTypes.LOGO_DETECTION_REQUEST: {
                state.isLoading = true;
                state.gotError = false;
                state.bboxCoords = null;
                return state;
            }

            // Succeed
            case imageExtraActionTypes.IMAGE_ENHANCEMENT_SUCCEED: {
                state.image = payload;
                state.isLoading = false;
                return state;
            }
            case imageExtraActionTypes.LOGO_DETECTION_SUCCEED: {
                state.isLoading = false;
                state.bboxCoords = payload;
                return state;
            }
            case imageExtraActionTypes.GET_IMAGE_ATTRIBUTES_SUCCEED: {
                state.attributeList = payload;
                state.isLoading = false;
                return state;
            }
            case imageExtraActionTypes.GET_ALCOHOL_ATTRIBUTES_SUCCEED: {
                state.alcoholAttributeList = payload;
                state.isLoading = false;
                return state;
            }
            case imageExtraActionTypes.GET_FASHION_ATTRIBUTES_SUCCEED: {
                state.fashionAttributeList = payload;
                state.isLoading = false;
                return state;
            }

            // Failed
            case imageExtraActionTypes.IMAGE_ENHANCEMENT_FAILED: {
                state.isLoading = false;
                state.gotError = true;
                return state;
            }
            case imageExtraActionTypes.LOGO_DETECTION_FAILED: {
                state.isLoading = false;
                state.gotError = true;
                return state;
            }
            case imageExtraActionTypes.GET_IMAGE_ATTRIBUTES_FAILED: {
                state.isLoading = false;
                state.gotError = true;
                return state;
            }
            case imageExtraActionTypes.GET_ALCOHOL_ATTRIBUTES_FAILED: {
                state.isLoading = false;
                state.gotError = true;
                return state;
            }
            case imageExtraActionTypes.GET_FASHION_ATTRIBUTES_FAILED: {
                state.isLoading = false;
                state.gotError = true;
                return state;
            }

            default: {
                return state;
            }
        }
    }
);

export default imageExtraData;
