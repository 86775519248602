import BootstrapTable from 'react-bootstrap-table-next';
import React, {useState} from "react";


const VerificationTable = ({data, onUpdateAttributes, onBackClick, onPrevClick, onNextClick, itemPlace}) => {

    const [selected, setSelected] = useState([]);

    const columns = [
        {
            dataField: 'attribute',
            text: 'Attribute',
        }, {
            dataField: 'currentValue',
            text: 'Current Value',
            formatter: (rowContent, row) => {
                if (row.currentValue && Array.isArray(row.currentValue)) {
                    return (
                        <div>
                            {row.currentValue.map((el) => {
                                if (row.attribute === 'nutrition_label') {
                                    return (
                                        <div className="row">
                                            <div className="col-6">{el.key}</div>
                                            <div className="col-6">{el.value?.toString()}</div>
                                        </div>
                                    )
                                } else if (el && typeof el === 'object') {
                                    return (
                                        <div>
                                            {Object.keys(el).map((el2) => {
                                                return (
                                                    <div className="row">
                                                        <div className="col-6">{el2}</div>
                                                        <div className="col-6">{el[el2].toString()}</div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div>{el.toString()}</div>
                                    )
                                }
                            })}
                        </div>
                    )
                } else if (row.currentValue && typeof row.currentValue === 'object') {
                    return (
                        <div>
                            {Object.keys(row.currentValue).map((el) => {
                                return (
                                    <div className="row">
                                        <div className="col-6">{el}</div>
                                        <div className="col-6">{row.currentValue[el].toString()}</div>
                                    </div>
                                )
                            })}
                        </div>
                    )
                } else {
                    return row.currentValue ? row.currentValue.toString() : row.currentValue
                }
            }
        }, {
            dataField: 'value',
            text: 'Value',
            formatter: (rowContent, row) => {
                if (row.value && Array.isArray(row.value)) {
                    return (
                        <div>
                            {row.value.map((el) => {
                                if (row.attribute === 'nutrition_label') {
                                    return (
                                        <div className="row">
                                            <div className="col-6">{el.key}</div>
                                            <div className="col-6">{el.value?.toString()}</div>
                                        </div>
                                    )
                                } else if (el && typeof el === 'object') {
                                    return (
                                        <div>
                                            {Object.keys(el).map((el2) => {
                                                return (
                                                    <div className="row">
                                                        <div className="col-6">{el2}</div>
                                                        <div className="col-6">{el[el2].toString()}</div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div>{el.toString()}</div>
                                    )
                                }
                            })}
                        </div>
                    )
                } else if (row.value && typeof row.value === 'object') {
                    return (
                        <div>
                            {Object.keys(row.value).map((el) => {
                                return (
                                    <div className="row">
                                        <div className="col-6">{el}</div>
                                        <div className="col-6">{row.value[el].toString()}</div>
                                    </div>
                                )
                            })}
                        </div>
                    )
                } else {
                    return row.value ? row.value.toString() : row.value
                }
            }
        },
        // {
        //     dataField: 'status',
        //     text: 'Verified Status',
        //     formatter: (rowContent, row) => {
        //         if(row.status === 1){
        //             return (
        //                 <input
        //                     type="checkbox"
        //                     className="form-check-input form-check-green"
        //                     name='check'
        //                     checked={true}
        //                     readOnly={true}
        //                 />
        //             )
        //         } else {
        //             return (
        //                 <input
        //                     type="checkbox"
        //                     className="form-check-input form-check-red"
        //                     name='check'
        //                     checked={false}
        //                     readOnly={true}
        //                 />
        //             )
        //         }
        //     }
        // }
        ];

    const handleOnSelectAll = (isSelect, rows) => {
        const ids = rows.map(r => r.attribute);
        if (isSelect) {
            setSelected(ids)
        } else {
            setSelected([]);
        }
    }

    const handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            setSelected([...selected, row.attribute])
        } else {
            setSelected(selected.filter(x => x !== row.attribute))
        }
    }

    const selectRow = {
        mode: 'checkbox',
        selectColumnPosition: 'right',
        clickToSelect: false,
        selected: selected,
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll
    };

    const onUpdateClick = () => {
        let dataObj = [];

        for(let i=0; i<selected.length; i++){
            dataObj[selected[i]] = data.find((el)=> el.attribute === selected[i]).value
        }

        onUpdateAttributes(dataObj)
    }

    const onBackButtonClick = () => {
      setSelected([]);
      onBackClick();
    }

    const onPrevButtonClick = () => {
        setSelected([]);
        onPrevClick();
    }

    const onNextButtonClick = () => {
        setSelected([]);
        onNextClick();
    }

    return (

        <div style={{marginBottom: 100, position: 'relative'}}>
            <div style={{
                background: "#FFF",
                borderRadius: "16px 16px 0 0",
                padding: "0 20px",
            }} className='verification-table-wrapper'>

                {onBackClick &&
                    <div className='d-flex justify-content-between p-4 mb-4 pt-5'>
                        <div onClick={onBackButtonClick} className='color-secondary normal-font link-custom'>Back</div>
                        <div className="d-flex">

                            {itemPlace !== 'first' &&
                                <div onClick={onPrevButtonClick} className='color-secondary normal-font link-custom'>
                                    Previous Product
                                </div>
                            }

                            {itemPlace !== 'last' &&
                                <div onClick={onNextButtonClick} className='ms-3 color-secondary normal-font link-custom'>
                                    Next Product
                                </div>
                            }

                        </div>
                    </div>
                }

                <BootstrapTable
                    keyField="attribute"
                    data={data}
                    columns={columns}
                    selectRow={selectRow}
                    classes="verification-table-class"
                    headerClasses="verification-table-header-class"
                    bodyClasses="verification-table-body-class"
                />
                <div
                    // style={{maxWidth:"max-content"}}
                    className='ms-auto'
                >
                    <button
                        onClick={onUpdateClick}
                        disabled={!selected.length}
                        className='mt-4 mb-2 btn button-custom'>
                        Update Attributes
                    </button>
                </div>
            </div>
        </div>
    )
}

export default VerificationTable;