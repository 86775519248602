import * as clientActionTypes from '../actionTypes/clientActionTypes'

import request from '../../helpers/requestHelper';


export const getAllClientData = () => async dispatch => {
    dispatch({type: clientActionTypes.GET_ALL_CLIENT_DATA_REQUEST});

    try {
        let res = await request({
            url: `/client/`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: clientActionTypes.GET_ALL_CLIENT_DATA_SUCCEED,
            payload: res
        });

    } catch (err) {
        dispatch({
            type: clientActionTypes.GET_ALL_CLIENT_DATA_FAILED,
            payload: err?.data ? err.data : err
        });
    }
}

export const getAllClientDataByUser = (clientCode) => async dispatch => {
    dispatch({type: clientActionTypes.GET_ALL_CLIENT_DATA_REQUEST});

    try {
        let res = await request({
            url: `/client/${clientCode}`,
            auth: true,
            method: 'GET',
        });

        let arr = [];
        arr.push(res.client)

        let data = {
            clients: arr
        }

        dispatch({
            type: clientActionTypes.GET_ALL_CLIENT_DATA_SUCCEED,
            payload: data
        });

    } catch (err) {
        dispatch({
            type: clientActionTypes.GET_ALL_CLIENT_DATA_FAILED,
            payload: err?.data ? err.data : err
        });
    }
}


export const addClientData = (data) => async dispatch => {
    dispatch({type: clientActionTypes.ADD_CLIENT_REQUEST});

    try {
        let res = await request({
            url: `/client/`,
            auth: true,
            method: 'POST',
            data: data
        });

        dispatch(getAllClientData());
        // dispatch({
        //     type: clientActionTypes.ADD_CLIENT_SUCCEED,
        //     payload: res
        // });

    } catch (err) {
        dispatch({
            type: clientActionTypes.ADD_CLIENT_FAILED,
            payload: err?.data ? err.data : err
        });
    }
}


export const updateClientData = (data) => async dispatch => {
    dispatch({type: clientActionTypes.UPDATE_CLIENT_REQUEST});

    let obj = {
        client: data.client,
        client_code: data.client_code,
        domain_name: data.domain_name,
        add_limit: data.add_limit,
        user_limit: data.user_limit
    }

    try {
        let res = await request({
            url: `/client/`,
            auth: true,
            method: 'PUT',
            data: obj
        });

        dispatch({
            type: clientActionTypes.UPDATE_CLIENT_SUCCEED,
            payload: data
        });

    } catch (err) {
        dispatch({
            type: clientActionTypes.UPDATE_CLIENT_FAILED,
            payload: err?.data ? err.data : err
        });
    }
}


export const getAllClientUserData = (id) => async dispatch => {
    dispatch({type: clientActionTypes.GET_CLIENT_USER_DATA_REQUEST});

    try {
        let res = await request({
            url: `/client/clientusers/`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: clientActionTypes.GET_CLIENT_USER_DATA_SUCCEED,
            payload: res
        });

    } catch (err) {
        dispatch({
            type: clientActionTypes.GET_CLIENT_USER_DATA_FAILED,
            payload: err?.data ? err.data : err
        });
    }
}


export const addClientUserData = (data, id, type) => async dispatch => {
    dispatch({type: clientActionTypes.ADD_CLIENT_USER_DATA_REQUEST});

    let api_1 = `/client/clientuser/${id}`
    let api_2 = '/client/clientuser'

    try {
        let res = await request({
            url: type === 2 ? api_2 : api_1,
            auth: true,
            method: 'POST',
            data: data
        });

        let obj = {
            client_code: id,
            data: data
        }

        if(obj.data.password) {
            delete obj.data.password;
        }

        dispatch({
            type: clientActionTypes.ADD_CLIENT_USER_DATA_SUCCEED,
            payload: obj
        });

    } catch (err) {
        dispatch({
            type: clientActionTypes.ADD_CLIENT_USER_DATA_FAILED,
            payload: err?.data ? err.data : err
        });
    }
}


export const updateClientUserData = (data, id, disable) => async dispatch => {
    dispatch({type: clientActionTypes.UPDATE_CLIENT_USER_DATA_REQUEST});

    let dataObj = {
        email: data.email,
        full_name: data.full_name,
        roles: data.roles,
        add_product: data.add_product,
        add_catalog: data.add_catalog,
        download_report: data.download_report,
        is_demo: data.is_demo,
    }

    if(data.password)
        dataObj.password = data.password;


    let disableRes = null;
    let status;

    if(disable === true){
        disableRes = await dispatch(deactivateUser(dataObj.email))
        status = disableRes ? 'DISABLED' : 'ACTIVE'
    } else if(disable === false){
        disableRes = await dispatch(activateUser(dataObj.email))
        status = disableRes ? 'ACTIVE' : 'DISABLED'
    }

    try {
        let res = await request({
            url: `/user/`,
            auth: true,
            method: 'PUT',
            data: dataObj
        });

        let obj = {
            client_code: id,
            data: dataObj
        }

        if(obj.data.password) {
            delete obj.data.password;
        }

        obj.data.status = status ? status : data.status;

        dispatch({
            type: clientActionTypes.UPDATE_CLIENT_USER_DATA_SUCCEED,
            payload: obj
        });

    } catch (err) {
        dispatch({
            type: clientActionTypes.UPDATE_CLIENT_USER_DATA_FAILED,
            payload: err?.data ? err.data : err
        });
    }
}

export const deactivateUser = (id) => async dispatch => {
    dispatch({type: clientActionTypes.DEACTIVATE_USER_REQUEST});

    let data = {
        email: id
    }

    try {
        let res = await request({
            url: `/user/disable`,
            auth: true,
            method: 'POST',
            data: data
        });

        return true;

    } catch (err) {
        return false;
    }
}

export const activateUser = (id) => async dispatch => {
    dispatch({type: clientActionTypes.ACTIVATE_USER_REQUEST});

    let data = {
        email: id
    }

    try {
        let res = await request({
            url: `/user/enable`,
            auth: true,
            method: 'POST',
            data: data
        });

        return true;

    } catch (err) {
        return false;
    }
}